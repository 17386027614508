import {Box, List, Typography} from "@mui/material";

export default function RefundPolicies() {
  return (
    <Box sx={{padding: "20px"}}>
      <Typography variant="h3" gutterBottom>
        Refund policy
      </Typography>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        1. Refund Eligibility
      </Typography>
      <p>
        We at SIFF, developed by Last 2 Brain Cells, strive to provide a
        satisfactory experience for our users. If you are not entirely satisfied
        with your purchase, we&apos;re here to help.
      </p>
      To be eligible for a refund:
      <List sx={{listStyle: "lower-alpha", paddingLeft: "30px"}}>
        <li>
          You must request a refund within 7 days of the original purchase date.
        </li>
        <li>
          Your account must be in good standing and comply with our Terms and
          Conditions.
        </li>
      </List>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        2. How to Request a Refund
      </Typography>
      <p>
        To request a refund, please contact us at{" "}
        <a href="mailto:contact@last2braincells.com">
          contact@last2braincells.com
        </a>{" "}
        with the following information:
      </p>
      <List sx={{listStyle: "lower-alpha", paddingLeft: "30px"}}>
        <li>
          Your full name and email address associated with your SIFF account.
        </li>
        <li>The reason for your refund request.</li>
        <li>
          Transaction details, including the purchase date and transaction ID.
        </li>
      </List>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        3. Refund Processing
      </Typography>
      <p>
        Once your refund request is received and approved, we will process the
        refund to the original method of payment within 7 days.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        4. Exceptions
      </Typography>
      <p>We reserve the right to refuse refunds in the following situations:</p>
      <List sx={{listStyle: "lower-alpha", paddingLeft: "30px"}}>
        <li>
          If the request is made after the 7 days from the original purchase
          date.
        </li>
        <li>
          If the refund request is due to a violation of our Terms and
          Conditions.
        </li>
      </List>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        5. Contact Information
      </Typography>
      <p>
        If you have any questions about our Refund Policy, please contact at{" "}
        <a href="mailto:contact@last2braincells.com">
          contact@last2braincells.com
        </a>
        .
      </p>
    </Box>
  );
}
