import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import codes from "country-calling-code";
import {
  Auth,
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import UserDataField from "src/Components/FormFillup/UserDataField";
import {app, db} from "src/firebaseConfig";
import {UserData} from "./FormFillup";

interface PersonalDetailsProps {
  setSkipAuthentication: Dispatch<SetStateAction<boolean>>;
  setShowGif: Dispatch<SetStateAction<boolean>>;
  userData: MutableRefObject<UserData | null>;
  authSetting: string | undefined | null;
}

export default function PersonalDetails({
  setSkipAuthentication,
  setShowGif,
  userData,
  authSetting,
}: PersonalDetailsProps) {
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(180);
  const [showSkipAuth, setShowSkipAuth] = useState(false);
  const recaptchaVerifier = useRef<RecaptchaVerifier>();
  const confirmationResult = useRef<ConfirmationResult>();
  const nameRef = useRef<HTMLInputElement>();
  const phoneNo = useRef<string>();

  const updateTimer = useCallback(() => {
    setTimer((prevTimer) => {
      if (prevTimer > 0) {
        return prevTimer - 1;
      } else {
        setShowSkipAuth(true);
        return 0;
      }
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(updateTimer, 1000);
    return () => clearInterval(intervalId);
  }, [updateTimer]);

  function handleVerify() {
    if (otp.length < 6) {
      toast.error("Enter valid OTP");
    } else {
      verifyOtp();
    }
  }

  //Function to generate OTP
  function generateOtp() {
    const phone = (document.getElementById("phone") as HTMLInputElement).value;
    if (phone.length != 10) {
      toast.error("Please enter valid phone number");
      return;
    }
    if (!nameRef?.current?.value && !phone) {
      toast.error("Please enter your name and phone number");
      return;
    } else if (!nameRef?.current?.value) {
      toast.error("Please Enter your name");
      return;
    } else if (!phone) {
      toast.error("Please enter your phone number");
      return;
    }

    const auth = getAuth(app);
    auth.useDeviceLanguage();
    recaptchaVerifier.current = new RecaptchaVerifier(auth, "submitDetails", {
      size: "normal",
      callback: () => {
        const submitDetailsElement = document.getElementById("submitDetails");
        if (submitDetailsElement) {
          submitDetailsElement.style.display = "none";
        }
        const countryCode = (
          document.getElementById("countryCode") as HTMLInputElement
        ).value;
        if (phone.length > 0) {
          phoneNo.current = `+${countryCode}${phone}`;
          setShowOtp(!showOtp);
          sendOtp(auth, `+${countryCode}${phone}`);
        }
      },
    });

    recaptchaVerifier.current?.render();
  }

  function verifyOtp() {
    const trimmedStr = nameRef.current!.value.replace(/\s+/g, " ");
    const firstName = trimmedStr.split(" ")[0];
    const lastName = trimmedStr.split(" ")[1];

    if (!lastName) {
      toast.error("Please enter your full name");
      return;
    }

    confirmationResult.current
      ?.confirm(otp)
      .then(async (result) => {
        const user = await getDoc(doc(db, "users", result.user.uid));
        if (!user.exists()) {
          await setDoc(
            doc(db, "users", result.user.uid),
            {
              firstname: firstName,
              lastname: lastName,
              phone: phoneNo.current,
              username: `${phoneNo.current}@siff`,
            },
            {
              merge: true,
            },
          );
        }
        toast.success("You are logged in");
        setShowGif(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Invalid OTP");
      });
  }

  function sendOtp(auth: Auth, phone: string) {
    setTimer(30);
    signInWithPhoneNumber(auth, phone, recaptchaVerifier.current!)
      .then((result) => {
        confirmationResult.current = result;
      })
      .catch((err) => {
        toast.error("An error occurred can you try again");
        console.error(err);
      });
  }

  function handleResend() {
    const auth = getAuth(app);
    const phone = (document.getElementById("phone") as HTMLInputElement).value;
    const countryCode = (
      document.getElementById("countryCode") as HTMLInputElement
    ).value;
    sendOtp(auth, `+${countryCode}${phone}`);
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#161616",
          padding: "1rem",
          borderRadius: "15px",
          marginTop: "10px",
        }}
      >
        <UserDataField
          name="Full name"
          onChange={(e) => (nameRef.current = e.target)}
        />
        <FormControl fullWidth>
          <Typography fontWeight={600} paddingBottom="7px">
            Phone Number*
          </Typography>
          <Box
            bgcolor="#262626"
            borderRadius="20px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
            overflow={"hidden"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl size="small" variant="outlined">
                <Select
                  defaultValue="91"
                  inputProps={{
                    IconComponent: () => null,
                    id: "countryCode",
                    style: {width: "1rem"},
                  }}
                  displayEmpty
                  required
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {border: 0},
                    width: "5.5rem",
                  }}
                  renderValue={(value) => (
                    <Box display="flex" flexDirection="row" gap={1}>
                      <Typography>+{value}</Typography>
                      <Box
                        component="img"
                        src={`https://flagcdn.com/h20/${codes
                          .find((country) => country.countryCodes[0] == value)
                          ?.isoCode2.toLowerCase()}.png`}
                        alt="flag"
                      />
                    </Box>
                  )}
                >
                  {codes.map((country, i) => (
                    <MenuItem value={country.countryCodes[0]} key={i}>
                      {country.country} - +{country.countryCodes[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <input
                id="phone"
                required={true}
                type="number"
                inputMode="numeric"
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  fontSize: "1rem",
                  height: "100%",
                  marginLeft: "10px",
                  color: "white",
                  width: "110px",
                }}
                placeholder="Phone number"
              />
            </Box>
            <Button
              disabled={showOtp && timer != 0}
              sx={{
                width: "fit-content",
                bgcolor: "white",
                color: "black",
                borderRadius: "20px",
                padding: "0.6rem 1rem",
                minWidth: "100px",
                "&:hover": {
                  bgcolor: "black",
                  color: "white",
                },
                "&:disabled": {
                  bgcolor: "#3c3c3c",
                  color: "#1e1e1e",
                },
              }}
              onClick={() => {
                showOtp ? handleResend() : generateOtp();
              }}
            >
              <Typography
                whiteSpace={"nowrap"}
                fontSize={{xs: "0.8rem", sm: "0.9rem"}}
                fontWeight={"600"}
                textTransform={"none"}
              >
                {showOtp ? "Resend OTP" : "Send OTP"}
              </Typography>
            </Button>
          </Box>
        </FormControl>
        <div
          id="submitDetails"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
          }}
        ></div>
        <Typography
          sx={{
            color: "#617AEC",
            textAlign: "center",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          To enable autofill please verify OTP, Any previous data entered will
          automatically autofill
        </Typography>

        {showOtp && (
          <Box sx={{marginTop: "1.5rem"}}>
            <Typography fontWeight={500} paddingBottom="7px" fontSize={"16px"}>
              Enter OTP
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                columnGap: "1.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // background: "red",
                  width: "100%",
                }}
              >
                <OTPInput
                  shouldAutoFocus
                  containerStyle={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  value={otp}
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="number"
                      inputMode="numeric"
                      style={{
                        background: "white",
                        borderRadius: "5px",
                        width: "15%",
                        height: "40px",
                        color: "black",
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                    />
                  )}
                  numInputs={6}
                  renderSeparator={<div style={{width: "0.1rem"}} />}
                  onChange={(val) => setOtp(val)}
                />
                <Button
                  onClick={handleVerify}
                  // fullWidth
                  sx={{
                    width: "120px",
                    marginTop: "10px",
                    bgcolor: "#617AEC",
                    color: "white",
                    borderRadius: "25px",
                    // paddingY: "10px",
                    "&:hover": {
                      color: "black",
                      bgcolor: "white",
                    },
                  }}
                >
                  <Typography
                    textTransform="none"
                    fontSize={"16px"}
                    fontWeight={500}
                  >
                    Verify
                  </Typography>
                </Button>
              </Box>
              {timer !== 0 ? (
                <Typography
                  sx={{color: "#ABACAC", fontSize: "12px", marginTop: "10px"}}
                >
                  Resend OTP in {timer} seconds
                </Typography>
              ) : (
                <Typography
                  sx={{color: "#ABACAC", fontSize: "12px", marginTop: "15px"}}
                >
                  Didn&apos;t get the OTP?{" "}
                  <span
                    onClick={handleResend}
                    style={{color: "#617AEC", cursor: "pointer"}}
                  >
                    Click here to resend
                  </span>
                </Typography>
              )}
              {showSkipAuth && authSetting != "Required" && (
                <Typography
                  sx={{
                    color: "#617AEC",
                    cursor: "pointer",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    setSkipAuthentication(true);
                    setShowGif(true);
                    const trimmedStr = nameRef.current!.value.replace(
                      /\s+/g,
                      " ",
                    );
                    userData.current = {
                      name: trimmedStr,
                      phone: phoneNo.current!,
                      refId: "",
                    };
                  }}
                >
                  or continue without verifying phone number
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
