import React from "react";
import ReactDOM from "react-dom/client";
import {Toaster} from "react-hot-toast";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "grey",
              color: "white",
            },
          },
          error: {
            style: {
              background: "grey",
              color: "white",
            },
          },
        }}
      />

      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
