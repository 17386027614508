import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import {Dispatch, ReactElement, SetStateAction, useEffect} from "react";
import ConditionalField from "src/Components/FormFillup/ConditionalField";
import DocumentField from "src/Components/FormFillup/DocumentField";
import FieldModel, {isFieldModel} from "src/Models/Form/FieldModel";
import ImageModel from "src/Models/Form/ImageModel";
import TextModel from "src/Models/Form/TextModel";
import {Errors, ISubmission} from "src/Pages/FormFillup/FormFillup";
import {isImageModel} from "src/Utils/Form/isImageModel";
import {isTextModel} from "src/Utils/Form/isTextModel";

interface FormFieldProps {
  field: FieldModel | ImageModel | TextModel;
  submission: ISubmission;
  setSubmission: Dispatch<SetStateAction<ISubmission>>;
  errors: Errors;
  setErrors: Dispatch<SetStateAction<Errors>>;
  owner: string;
  files: {[key: string]: File};
  setFiles: Dispatch<SetStateAction<{[key: string]: File}>>;
  disable: boolean;
  formId: string;
}

export default function FormField({
  field,
  submission,
  setSubmission,
  owner,
  errors,
  setErrors,
  files,
  setFiles,
  disable,
  formId,
}: FormFieldProps) {
  useEffect(() => {
    if (isFieldModel(field)) {
      if (field.question && errors[field.question] === undefined) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field.question]: false,
        }));
      }
    }
  }, []);

  if (isImageModel(field)) {
    return (
      <Box width="100%" display="flex" justifyContent="center" height="40%">
        <img
          alt="imageField"
          src={field.url.replaceAll("10.0.2.2", "127.0.0.1")}
          width="100%"
          style={{paddingBottom: "20px", borderRadius: "5px"}}
        />
      </Box>
    );
  } else if (isTextModel(field)) {
    return (
      <Typography
        fontWeight={400}
        paddingBottom="27px"
        sx={{whiteSpace: "pre-wrap"}}
      >
        {field.text}
      </Typography>
    );
  }

  function onChange(value: any) {
    if (isFieldModel(field)) {
      setSubmission({
        ...submission,
        [field.id ?? field.question]: value.target ? value.target.value : value,
      });
      localStorage[formId] = JSON.stringify(submission);
    }
  }

  const currField = field as FieldModel;
  let input: ReactElement;
  if (currField.inputType == "Number") {
    input = (
      <FormControl fullWidth>
        <TextField
          disabled={disable}
          type="number"
          variant="standard"
          required={currField.required}
          value={submission[currField.id ?? currField.question]}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            bgcolor: "#262626",
            borderRadius: "25px",
            outline: "none",
            border: "none",
            paddingX: "15px",
            paddingY: "3px",
          }}
          onChange={onChange}
        />
      </FormControl>
    );
  } else if (currField.inputType == "Drop down") {
    input = (
      <FormControl
        disabled={disable}
        fullWidth
        required={currField.required}
        sx={{
          border: "1px solid white",
          borderRadius: "5px",
        }}
        size="small"
      >
        <Select
          required={currField.required}
          value={submission[currField.id ?? currField.question] ?? ""}
          onChange={onChange}
          MenuProps={{
            disableScrollLock: false,
          }}
        >
          {currField.options?.map((option, i) => (
            <MenuItem key={i} value={option.option}>
              {option.option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (currField.inputType == "Multiple choice") {
    const value = submission[currField.id ?? currField.question];
    input = (
      <FormControl fullWidth required={currField.required} disabled={disable}>
        <RadioGroup
          value={value}
          onChange={(e) => {
            setErrors({
              ...errors,
              [currField.question]: false,
            });

            onChange(e.target.value);
          }}
        >
          {currField.options?.map((elem, idx) => (
            <FormControlLabel
              value={elem.option}
              key={idx}
              control={<Radio checked={value == elem.option} />}
              label={elem.option}
            />
          ))}
          {errors[currField.question] && currField.required && (
            <Typography color={"error"}>Please select an option</Typography>
          )}
        </RadioGroup>
      </FormControl>
    );
  } else if (currField.inputType == "Check box") {
    const currValue = submission[currField.id ?? currField.question];
    let value: string[] = [];
    if (currValue) {
      value = submission[currField.id ?? currField.question];
    }

    const handleCheckboxChange = (option: string) => {
      if (value.includes(option)) {
        value.splice(value.indexOf(option), 1);
      } else {
        value.push(option);
      }
      setErrors({
        ...errors,
        [currField.question]: false,
      });
      onChange(value);
    };

    input = (
      <FormControl required={currField.required} disabled={disable}>
        <FormGroup>
          {currField.options?.map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={value.includes(option.option)}
                  name={option.option}
                  onChange={() => handleCheckboxChange(option.option)}
                />
              }
              label={option.option}
            />
          ))}
          {errors[currField.question] && currField.required && (
            <Typography color="error">Please select an option </Typography>
          )}
        </FormGroup>
      </FormControl>
    );
  } else if (currField.inputType == "Range") {
    input = (
      <FormControl required={currField.required} fullWidth disabled={disable}>
        <Slider
          min={currField.lowerLimit}
          max={currField.upperLimit}
          value={submission[currField.id ?? currField.question]}
          valueLabelDisplay="auto"
          onChange={(_e, value) => {
            setErrors({
              ...errors,
              [currField.question]: false,
            });
            onChange(value);
          }}
        />
        {errors[currField.question] && currField.required && (
          <Typography color="error">Please select a Range </Typography>
        )}
      </FormControl>
    );
  } else if (currField.inputType == "Date") {
    input = (
      <FormControl fullWidth required={currField.required} disabled={disable}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            slotProps={{textField: {required: currField.required}}}
            value={submission[currField.id ?? currField.question ?? dayjs()]}
            onChange={onChange}
          />
        </LocalizationProvider>
      </FormControl>
    );
  } else if (currField.inputType == "Time") {
    input = (
      <FormControl fullWidth required={currField.required} disabled={disable}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <TimePicker
            slotProps={{textField: {required: currField.required}}}
            value={submission[currField.id ?? currField.question] ?? dayjs()}
            onChange={onChange}
          />
        </LocalizationProvider>
      </FormControl>
    );
  } else if (currField.inputType == "Document") {
    input = (
      <Box>
        {currField.docs?.map((doc) => (
          <DocumentField
            key={doc.name}
            docField={doc}
            field={currField}
            owner={owner}
            submission={submission}
            setSubmission={setSubmission}
            files={files}
            setFiles={setFiles}
          />
        ))}
      </Box>
    );
  } else {
    input = (
      <FormControl fullWidth>
        <TextField
          disabled={disable}
          type="text"
          label=""
          required={currField.required}
          value={submission[currField.id ?? currField.question]}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            bgcolor: "#262626",
            borderRadius: "25px",
            outline: "none",
            border: "none",
            paddingX: "15px",
            paddingY: "3px",
          }}
          onChange={onChange}
        />
      </FormControl>
    );
  }

  return (
    <Box paddingBottom="20px">
      {currField.inputType != "Document" && (
        <Typography
          fontWeight={600}
          paddingBottom="7px"
          color={disable ? "#ABACAC" : "#FFFFFF"}
        >
          {currField.question}
          {currField.required ? "*" : ""}
        </Typography>
      )}
      {input}
      <ConditionalField
        field={currField}
        submission={submission}
        setSubmission={setSubmission}
      />
    </Box>
  );
}
