import {
  Box,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import DynamicQr from "src/Pages/DynamicQR/DynamicQr";
import FormFillup from "src/Pages/FormFillup";
import Help from "src/Pages/Help/Help";
import PrivacyPolicies from "src/Pages/Policies/PrivacyPolicies";
import SignIn from "src/Pages/SignIn/SignIn";
import Submission from "src/Pages/Submission/Submission";
import Landing from "src/Pages/Landing/Landing";
import Stats from "src/Pages/Stats/Stats";
import TermsAndConditions from "src/Pages/TermsAndConditions";
import RefundPolicies from "src/Pages/RefundPolicies";
import AboutUs from "./Pages/About-us/AboutUs";
import {HelmetProvider} from "react-helmet-async";
import AddBlog from "./Pages/Blog/AddBlog";
import AllBlog from "./Pages/Blog/AllBlog";
import EachBlog from "./Pages/Blog/EachBlog";

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "black",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h3: {
      fontWeight: 600,
      fontSize: "1.8rem",
    },
  },
});

theme = responsiveFontSizes(theme);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/form/:formId" element={<FormFillup />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/privacy-policies" element={<PrivacyPolicies />} />
            <Route path="/qr/:id" element={<DynamicQr />} />
            <Route path="/submission" element={<Submission />} />
            <Route path="/help" element={<Help />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/stats" element={<Stats />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/refund-policy" element={<RefundPolicies />} />
            <Route path="/addblog" element={<AddBlog />} />
            <Route path="/blog" element={<AllBlog />} />
            <Route path="/blog/:title" element={<EachBlog />} />
          </Routes>
        </Box>
      </HelmetProvider>
    </ThemeProvider>
  );
}
