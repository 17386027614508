import {Box, Button, Typography} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useNavigate} from "react-router-dom";
export default function AboutUs() {
  const navigate = useNavigate();

  return (
    <Box
      width={["90%", "80%", "50%", "35%"]}
      display="flex"
      flexDirection="column"
      paddingTop="20px"
      paddingBottom="4rem"
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{cursor: "pointer"}} onClick={() => navigate("/")}>
          <ArrowBackIosIcon />
        </div>
        <Typography fontSize={"24px"} color="white">
          About us
        </Typography>
        <div></div>
      </Box>
      <Box
        sx={{
          background: "rgba(65, 209, 14,0.15)",
          padding: "1.5rem",
          borderRadius: "15px",
          marginTop: "5rem",
        }}
      >
        <Typography color="white" textAlign={"center"} sx={{fontSize: "1em"}}>
          We are a dedicated team of developers, marketers, and creatives
          committed to maximizing the efficiency of our product. We deeply
          understand the needs of small businesses and recognize the
          transformative potential of our solution. If you have any questions,
          feedback, or inquiries, please don&apos;t hesitate to reach out to us
          directly via chat or phone.
          <br />
          <br />
          Your input is invaluable as we continue to refine and improve our
          platform to better serve you
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
          gap: "1rem",
        }}
      >
        <Button
          fullWidth
          sx={{
            bgcolor: "#262626",
            color: "white",
            borderRadius: "25px",
            padding: "1rem 1rem",
            "&:hover": {
              bgcolor: "#424242",
              color: "white",
            },
            display: "flex",
            gap: "1rem",
          }}
          onClick={() => {
            window.location.href =
              "mailto:contact@last2braincells.com?subject=I had to say something about your amazing product";
          }}
        >
          <img src="/mail.svg" />
          <Typography fontSize={"1rem"} textTransform="none">
            Email
          </Typography>
        </Button>
        <Button
          fullWidth
          sx={{
            bgcolor: "#262626",
            color: "white",
            borderRadius: "25px",
            padding: "1rem 1rem",
            "&:hover": {
              bgcolor: "#424242",
              color: "white",
            },
            display: "flex",
            gap: "1rem",
          }}
          onClick={() => {
            window.location.href = "https://wa.me/+918826350936";
          }}
        >
          <img src="/whatsapp.svg" />
          <Typography fontSize={"1rem"} textTransform="none">
            Whatsapp
          </Typography>
        </Button>
        <Button
          fullWidth
          sx={{
            bgcolor: "#262626",
            color: "white",
            borderRadius: "25px",
            padding: "1rem 1rem",
            "&:hover": {
              bgcolor: "#424242",
              color: "white",
            },
            display: "flex",
            gap: "1rem",
          }}
          onClick={() => (window.location.href = "tel:+918826350936")}
        >
          <img src="/call.svg" />
          <Typography fontSize={"1rem"} textTransform="none">
            Phone
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
