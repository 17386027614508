import {Box, Typography} from "@mui/material";
import FieldModel, {Doc} from "src/Models/Form/FieldModel";
import {ISubmission} from "src/Pages/FormFillup/FormFillup";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import documents from "src/Utils/Documents";
import docIcon from "./Document.svg";
import uploadIcon from "./upload.svg";
import "./DocumentField.css";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {app, db} from "src/firebaseConfig";
import {collection, doc, getDoc} from "firebase/firestore";
import imageToText from "src/Utils/imageToText";
import saveDoc from "src/Utils/saveDoc";
import aadhaar from "./aadhaar.png";
import pan from "./pan.png";
import passport from "./passport.png";
import dl from "./dl.png";
import boardCertificate from "./boardCertificate.png";
import voter from "./voter.png";
import toast from "react-hot-toast";

interface DocumentFieldProps {
  field: FieldModel;
  docField: Doc;
  owner: string;
  submission: ISubmission;
  setSubmission: Dispatch<SetStateAction<ISubmission>>;
  files: {[key: string]: File};
  setFiles: Dispatch<SetStateAction<{[key: string]: File}>>;
}

export default function DocumentField({
  field,
  docField,
  owner,
  submission,
  setSubmission,
  files,
  setFiles,
}: DocumentFieldProps) {
  const [icon, setIcon] = useState(docIcon);
  const [loggedIn, setLoggedIn] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authCompleted, setAuthCompleted] = useState(false);
  const uid = useRef("");
  const docInfo = useMemo(
    () => documents.find((currDocInfo) => docField.name == currDocInfo.name),
    [],
  );
  useEffect(() => {
    setLoading(true);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
        uid.current = user.uid;
      }
      setAuthCompleted(true);
    });

    if (docField.type == "siffDoc") {
      const name = docField.name;
      if (name === "Aadhaar") {
        setIcon(aadhaar);
      } else if (name === "PAN") {
        setIcon(pan);
      } else if (name === "Passport") {
        setIcon(passport);
      } else if (name === "Voter ID") {
        setIcon(voter);
      } else if (name === "Board certificate") {
        setIcon(boardCertificate);
      } else if (name === "Driving license") {
        setIcon(dl);
      }
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [authCompleted]);

  let description = field.question;
  let issuer = `${owner} is asking for this document`;
  let fedSystem = "Custom document";
  const file = submission[docField.name] as string;

  if (docField.type == "siffDoc") {
    description = docInfo!.description;
    issuer = docInfo!.issuer;
    fedSystem = docInfo!.federalSystem;
  }

  async function handleSiffDoc() {
    if (submission[docField.name]) {
      setSubmission({...submission, [docField.name]: undefined});
    } else {
      const kycRef = doc(
        collection(doc(db, "users", uid.current), "kycDocs"),
        docField.name.toUpperCase(),
      );
      const data = await getDoc(kycRef);
      if (data.exists()) {
        const docData = data.data();
        setSubmission({...submission, [docField.name]: docData.url});
      } else {
        const inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/png, image/jpeg";
        inputElement.addEventListener("change", async (event) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const selectedFile = event.target!.files[0];
          if (selectedFile) {
            const text = await imageToText(selectedFile);
            const match = text.match(docInfo!.regex!);
            if (match) {
              const number = match[0];
              const filePath = await saveDoc(
                docField,
                uid.current,
                number,
                selectedFile,
              );
              setSubmission({...submission, [docField.name]: filePath});
            } else {
              toast.error("Documents do not match!");
            }
          } else {
            console.log("No file selected");
          }
        });

        // Trigger the file input dialog
        inputElement.click();
      }
    }
  }

  function getButtonColor(): string {
    if (hasError) {
      return "red";
    } else if (files[docField.name]) {
      return "#3C3C3C";
    } else {
      if (
        submission[docField.name] &&
        docField.type === "siffDoc" &&
        loggedIn
      ) {
        return "#3C3C3C";
      } else {
        return "#4361ee";
      }
    }
  }

  function getName(): string {
    if (files[docField.name]) {
      return file.split("\\").at(-1)!.slice(0, 40);
    } else {
      if (
        submission[docField.name] &&
        docField.type === "siffDoc" &&
        loggedIn
      ) {
        return "Attached";
      } else {
        return "Upload";
      }
    }
  }

  if (loading) {
    return null;
  }

  return (
    <Box
      borderRadius="5px"
      padding="10px"
      bgcolor="#1E1E1E"
      marginBottom="10px"
    >
      <Box display="flex" flexDirection="row">
        <Box
          bgcolor="white"
          height="3rem"
          width="3rem"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={icon}
            alt="Document icon"
            width={docField.type == "siffDoc" ? "100%" : "40%"}
          />
        </Box>
        <Box marginLeft="1rem" width="90%">
          <Typography fontSize=".6rem" color="#ABACAC">
            {issuer}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap=".5rem"
          >
            <Typography
              sx={{
                maxWidth: "300px",
                whiteSpace: "wrap",
              }}
            >
              {docField.name}
            </Typography>
            <Box
              bgcolor="#545454"
              width="fit-content"
              height="fit-content"
              paddingX="4px"
              borderRadius="2px"
            >
              <Typography fontSize=".7rem">{fedSystem}</Typography>
            </Box>
          </Box>
          <Typography fontSize=".7rem" fontWeight={300}>
            {description}
          </Typography>
        </Box>
      </Box>
      <label
        htmlFor={docField.name}
        id="customDocButton"
        style={{backgroundColor: getButtonColor()}}
        onClick={
          docField.type === "siffDoc" && loggedIn ? handleSiffDoc : undefined
        }
      >
        {getName() === "Upload" && <img src={uploadIcon} alt="Upload icon" />}
        <Typography
          fontSize=".8rem"
          marginLeft="5px"
          color={getName() === "Upload" ? "white" : "#7DFF84"}
        >
          {getName()}
        </Typography>
      </label>
      {(docField.type === "siffDoc" && loggedIn) || files[docField.name] ? (
        <input
          type="hidden"
          id={docField.name}
          onInvalid={() => setHasError(true)}
          required={docField.required}
          value={submission[docField.name]}
        />
      ) : (
        <input
          id={docField.name}
          type="file"
          onInvalid={() => setHasError(true)}
          accept="image/png, image/jpeg, application/pdf"
          required={docField.required}
          onChange={(e) => {
            const filesElem = e.target;
            const newFiles = filesElem.files;
            if (newFiles && newFiles[0]) {
              setFiles({...files, [docField.name]: newFiles[0]});
            }
            setHasError(false);
            setSubmission({...submission, [docField.name]: e.target.value});
          }}
        />
      )}
    </Box>
  );
}
