import {Box, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

interface CheckMarkProp {
  text: string;
}

export default function CheckMark({text}: CheckMarkProp) {
  return (
    <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
      <DoneIcon
        sx={{
          color: "#D9D9D9",
          background: "#4361EE",
          borderRadius: "50%",
          textAlign: "center",
          width: "15px",
          height: "15px",
          p: "4px",
        }}
      />
      <Typography>{text}</Typography>
    </Box>
  );
}
