import {Box, Typography} from "@mui/material";
import {collection, getDocs, query, where} from "firebase/firestore";
import {useEffect, useState} from "react";
import Markdown from "react-markdown";
import {useNavigate, useParams} from "react-router-dom";
import {db} from "src/firebaseConfig";
import "./blog.css";
import toast from "react-hot-toast";
import {Blog} from "./Interfaces";

export default function EachBlog() {
  const {title} = useParams();
  const [content, setContent] = useState("");
  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
    const fetchEachBlog = async () => {
      const blogQuery = query(
        collection(db, "blog"),
        where("title", "==", title),
      );
      const querySnapshot = await getDocs(blogQuery);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setContent(data.content);
      } else {
        toast.error("No blogs exist with this title", {id: "blog"});
      }
    };

    fetchEachBlog();
  }, [title]);

  return (
    <Box
      sx={{
        width: {
          xs: "90vw",
          md: "80vw",
          lg: "70vw",
          xl: "50vw",
        },
        margin: "0 auto",
      }}
    >
      <Markdown className={"markdownPage"}>{content}</Markdown>
      <BlogSuggestion currTitle={title} />
    </Box>
  );
}

function BlogSuggestion({currTitle}: {currTitle: string | undefined}) {
  const [suggestionBlogs, setSuggestionBlogs] = useState<Blog[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogCollectionRef = collection(db, "blog");
        const querySnapshot = await getDocs(blogCollectionRef);

        const blogData: Blog[] = [];
        querySnapshot.forEach((doc) => {
          const blog: Blog = {id: doc.id, ...doc.data()} as Blog;
          blogData.push(blog);
        });
        blogData.forEach((entry) => {
          if (typeof entry.createdAt !== "string") {
            const timestamp = entry.createdAt;
            const date = new Date(
              timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
            );
            const options: Intl.DateTimeFormatOptions = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            entry.createdAt = date.toLocaleDateString("en-US", options);
          }
        });

        const suggestionBlogs: Blog[] = blogData.filter((elem) => {
          return elem.title != currTitle;
        });

        setSuggestionBlogs(suggestionBlogs);
      } catch (err) {
        toast.error("Something went wrong");
        console.log("Error fetching data:", err);
      }
    };

    fetchData();
  });

  return (
    <>
      {suggestionBlogs.length !== 0 && (
        <>
          <Typography sx={{fontSize: "2rem", fontWeight: "600"}}>
            More for you to read
          </Typography>
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "grid",
              },
              flexDirection: "column",
              gridTemplateColumns: "1fr 1fr",
              gap: "2rem",
              width: {
                xs: "90vw",
                md: "80vw",
                lg: "70vw",
                xl: "50vw",
              },
              py: "2rem",
            }}
          >
            {suggestionBlogs?.slice(0, 4)?.map((elem: Blog, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    background: "#3d3d3d",
                    cursor: "pointer",
                    p: "0.5rem",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(`/blog/${elem.title}`);
                  }}
                >
                  <img
                    src={elem.headImage}
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "14px 14px 0 0",
                    }}
                  />
                  <Typography>
                    {typeof elem.createdAt == "string" ? elem.createdAt : ""} |{" "}
                    {elem.writer}
                  </Typography>
                  <Typography sx={{fontSize: "1.5rem"}}>
                    {elem.title.split("-").join(" ")}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
}
