import {ISubmission} from "src/Pages/FormFillup/FormFillup";
import {db, storage} from "src/firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import FieldModel, {isFieldModel} from "src/Models/Form/FieldModel";
import ImageModel from "src/Models/Form/ImageModel";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import imageToText from "src/Utils/imageToText";
import documents from "../Documents";
import saveDoc from "src/Utils/saveDoc";
import TextModel from "src/Models/Form/TextModel";
import dayjs from "dayjs";

/**
 * Submits a form submission to the specified form ID and stores it in the database.
 *
 * @param {ISubmission} submission - The submission data to be stored.
 * @param {string} formId - The ID of the form to submit the data to.
 * @param {string} uid - The ID of the user submitting the form.
 * @param fields - A list of fields in the form
 * @return {Promise} - A Promise that resolves when the submission is successfully stored in the database.
 */
export default async function submitForm(
  submission: ISubmission,
  formId: string,
  uid: string,
  fields: (FieldModel | ImageModel | TextModel)[],
  tempUser = false,
): Promise<
  void | DocumentReference | {refId: string; time: string; user: string}
> {
  const userCollectionRef = collection(
    doc(db, tempUser ? "tempUsers" : "users", uid),
    "submissions",
  );
  const answers: {[key: string]: any} = {};

  for (const field of fields) {
    if (isFieldModel(field)) {
      if (submission[field.id ?? field.question] && field.tag) {
        answers[field.tag] = submission[field.id ?? field.question];
      }
      if (field.inputType === "Document") {
        for (const docField of field.docs!) {
          const file = submission[docField.name] as File | undefined;
          if (file) {
            if (docField.type === "customDoc") {
              const fileRef = ref(
                storage,
                `${tempUser ? "tempUsers" : "users"}/${uid}/files/${file.name}`,
              );
              await uploadBytes(fileRef, file);
              submission[docField.name] = await getDownloadURL(fileRef);
            } else {
              const kycRef = doc(
                collection(
                  doc(db, tempUser ? "tempUsers" : "users", uid),
                  "kycDocs",
                ),
                docField.name.toUpperCase(),
              );
              const data = await getDoc(kycRef);
              if (data.exists()) {
                const docData = data.data();
                submission[docField.name] = docData.url;
              } else {
                const docInfo = documents.find(
                  (currDocInfo) => docField.name == currDocInfo.name,
                );
                const text = await imageToText(file);
                const match = text.match(docInfo!.regex!);
                if (match) {
                  const number = match[0];
                  submission[docField.name] = await saveDoc(
                    docField,
                    uid,
                    number,
                    file,
                  );
                }
              }
            }
          }
        }
      }
    }
  }
  const data = {
    submittedAt: Timestamp.now(),
    user: uid,
    answers: submission,
  };

  await setDoc(doc(userCollectionRef, formId), data, {merge: true});

  const docRef = await addDoc(
    collection(doc(db, "forms", formId), "submissions"),
    data,
  );

  const date = dayjs();

  const obj = {
    refId: docRef.id,
    time: date.format("DD MMM, hh:mm a"),
    user: uid,
  };

  const answersCollection = collection(
    doc(db, tempUser ? "tempUsers" : "users", uid),
    "answers",
  );
  localStorage.answer = JSON.stringify(answers);
  if (Object.keys(answers).length > 0) {
    await setDoc(doc(answersCollection, uid), answers, {merge: true});
    console.log("Saved answers");
  } else {
    console.error("Cannot save empty answers");
  }
  return obj;
}
