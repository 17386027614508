import {Box, Typography} from "@mui/material";

export default function TermsAndConditions() {
  return (
    <Box sx={{padding: "20px"}}>
      <Typography variant="h3" gutterBottom>
        Terms & Conditions
      </Typography>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        1. Acceptance of Terms
      </Typography>
      <p>
        Welcome to the SIFF, developed by Last 2 Brain Cells. By downloading,
        accessing, or using the SIFF app, you agree to comply with and be bound
        by these Terms and Conditions.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        2. User Registration
      </Typography>
      <p>
        To access certain features of the App, you may be required to register
        for an account. You agree to provide accurate, current, and complete
        information during the registration process and to update such
        information to keep it accurate, current, and complete.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        3. User Content
      </Typography>
      <p>
        Users are solely responsible for the content they create, submit, or
        display on the App. Last 2 Brain Cells reserves the right to remove or
        modify any content that violates these Terms and Conditions or is deemed
        inappropriate.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        4. Payment and Billing
      </Typography>
      <p>
        The App may offer features that require payment. By using such features,
        you agree to pay all fees and charges associated with your account. Last
        2 Brain Cells reserves the right to change its pricing and to institute
        new charges at any time.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        5. Privacy
      </Typography>
      <p>
        Your use of the App is also governed by our{" "}
        <a href="/privacy-policies">Privacy Policy</a>, which can be found at
        the end of these Terms and Conditions.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        6. Intellectual Property
      </Typography>
      <p>
        All content and materials available on the App, including but not
        limited to text, graphics, logos, button icons, images, and software,
        are the property of Last 2 Brain Cells or its licensors and are
        protected by applicable intellectual property laws.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        7. Limitation of Liability
      </Typography>
      <p>
        Last 2 Brain Cells shall not be liable for any indirect, incidental,
        special, consequential, or punitive damages, or any loss of profits or
        revenues, whether incurred directly or indirectly.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        8. Termination
      </Typography>
      <p>
        Last 2 Brain Cells reserves the right to terminate or suspend your
        account and access to the App for any reason, including, without
        limitation, breach of these Terms and Conditions.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        9. Changes to Terms
      </Typography>
      <p>
        Last 2 Brain Cells reserves the right to modify, amend, or change these
        Terms and Conditions at any time. Notification of changes will be
        provided through the App or other means.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        10. Governing Law
      </Typography>
      <p>
        These Terms and Conditions are governed by and construed in accordance
        with the laws of the Indian Government.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        11. Contact Information
      </Typography>
      <p>
        If you have any questions about this Terms & Conditions, please contact
        us at{" "}
        <a href="mailto:contact@last2braincells.com">
          contact@last2braincells.com
        </a>
      </p>
    </Box>
  );
}
