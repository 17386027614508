import {Box, FormControl, TextField, Typography} from "@mui/material";
import {ChangeEvent} from "react";

interface UserDataFieldProps {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function UserDataField({name, onChange}: UserDataFieldProps) {
  return (
    <Box paddingBottom="20px">
      <Typography fontWeight={600} paddingBottom="7px">
        {name}*
      </Typography>
      <FormControl fullWidth>
        <TextField
          type="text"
          label=""
          required={true}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            bgcolor: "#262626",
            borderRadius: "25px",
            outline: "none",
            border: "none",
            paddingX: "15px",
            paddingY: "4px",
          }}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  );
}
