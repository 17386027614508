import {Box, Button, Container, Link, Typography} from "@mui/material";
import CheckMark from "src/Components/Home/CheckMark";
import {
  Apple,
  ArrowOutward,
  Instagram,
  LinkedIn,
  Mail,
  Twitter,
} from "@mui/icons-material";
import {useRef} from "react";
import "./landing.css";
import {ReactTyped} from "react-typed";
import {useNavigate} from "react-router-dom";
import Navbar from "src/Components/Global/Navbar";

function Landing() {
  const navigate = useNavigate();
  const featureRef = useRef<HTMLElement>(null);

  const business = [
    "Automate invoicing and billing",
    "Directly share on WhatsApp or Email",
    "Automate client verification",
    "Automate employee onboarding, verification and tracking",
    "Automate payment reminders",
    "Automate inventory management",
    "Automate accounting",
    "Efficient Lead management",
    "Efficient Shipment tracking",
  ];

  const users = [
    "Helps you autofill forms with your government IDs, no more filling the same information over and over again.",
    "Keep your data secure in your phone and keep track of where you have given your data",
    "One stop solution for all your information",
    "Create forms easily with AI and automate your data management",
  ];

  function handleFeatureClick() {
    if (featureRef.current) {
      featureRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      console.error("featureRef.current is null or undefined");
    }
  }

  function handleBookDemo() {
    const imagine_link = document.createElement("a");
    imagine_link.href = "https://calendly.com/last2braincells/siff-demo-call";
    imagine_link.click();
    imagine_link.remove();
  }

  function handleDownloadApp() {
    const download_link = document.createElement("a");
    download_link.href =
      "https://play.google.com/store/apps/details?id=com.l2bc.siff";
    download_link.click();
    download_link.remove();
  }

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "3rem",
        mb: "20px",
      }}
    >
      <Navbar />

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6rem",
          transition: "all 0.3s ease",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: {
              xs: "1.5rem",
              md: "4rem",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: {
                xs: "1.5rem",
                md: "2rem",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "2.4rem",
                  md: "3rem",
                },
              }}
              fontWeight={"700"}
              lineHeight={"1.2"}
              color={"white"}
            >
              Automate your<br></br>
              {
                <ReactTyped
                  strings={[
                    "Invoicing",
                    "GST billing",
                    "Shipment tracking",
                    "Lead tracking",
                    "Business",
                  ]}
                  typeSpeed={60}
                  backSpeed={60}
                  loop
                  // style={{color: "#4361EE"}}
                />
              }
            </Typography>
            <Typography fontSize={"1rem"} color={"#d9d9d9"}>
              SIFF helps you automate your business by managing all data
              transfer. So you only focus on your business and let us do the
              boring stuff.
            </Typography>
            <span
              style={{display: "block", color: "#617AEC", cursor: "pointer"}}
              onClick={handleFeatureClick}
            >
              Check all features here ➔
            </span>
            <Button
              // fullWidth
              onClick={() => {
                handleDownloadApp();
              }}
              sx={{
                margin: "0 0",
                width: {
                  xs: "240px",
                  md: "300px",
                },
                bgcolor: "#4361EE",
                color: "white",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                py: "10px",
                transition: "box-shadow 0.5s ease", // Add transition for smooth effect
                "&:hover": {
                  boxShadow: "0px 0px 10px 5px rgba(255, 255, 255, 0.3)",
                },
              }}
            >
              <Typography
                textTransform="none"
                fontSize={"1rem"}
                fontWeight={500}
                color={"#d9d9d9"}
              >
                Download SIFF App
              </Typography>
              <Apple sx={{color: "#d9d9d9"}} />
              <img src="/playstore.svg" />
            </Button>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <img src="/Landing1.webp" style={{width: "100%"}} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "1.5rem",
          }}
        >
          <Typography
            fontSize={"2.4rem"}
            fontWeight={"700"}
            lineHeight={"1.2"}
            color={"white"}
          >
            Powered by
          </Typography>
          <Typography fontSize={"1rem"} color={"#d9d9d9"}>
            Keep your verified information secure! And track where your
            information goes.
          </Typography>
          <Box
            sx={{
              display: {
                xs: "grid",
                md: "flex",
              },
              gridTemplateColumns: "1fr 1fr",
              alignItems: "center",
              width: "100%",
              placeItems: "center",
              gap: "3rem",
            }}
          >
            <img src="/homeMinistry.svg" width="80%" />
            <img src="/digilocker.svg" width="80%" />
            <img src="/apiSetu.png" width="80%" />
            <img src="/digitalIndia.svg" width="80%" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row-reverse",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: {
              xs: "1.5rem",
              md: "4rem",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <Typography
              fontSize={"2.4rem"}
              fontWeight={"700"}
              lineHeight={"1.2"}
              color="white"
            >
              Never loose a lead!
            </Typography>
            <Typography fontSize={"1rem"} color="#d9d9d9">
              SIFF helps you manage your leads and directly reach out to them on
              WhatsApp or Email. Automate your sales cycle with just a click!
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <img width="100%" src="/Landing2.webp" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "1.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <Typography
              fontSize={"2.4rem"}
              fontWeight={"700"}
              lineHeight={"1.2"}
              width={"100%"}
              color={"white"}
            >
              Create Invoices with just 1 Click!
            </Typography>
            <Typography fontSize={"1rem"} color="#d9d9d9">
              Get your purchase form filled, click on generate invoice, Share
              directly on WhatsApp, email or message. It’s that easy.
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <img width="100%" src="/Landing3.webp" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "1.5rem",
          }}
        >
          <Typography
            fontSize={"2.4rem"}
            fontWeight={"700"}
            lineHeight={"1.2"}
            color={"white"}
          >
            Save all Your Information in One Place!
          </Typography>
          <Typography
            fontSize={"1rem"}
            sx={{
              color: "#d9d9d9",
              width: {
                xs: "100%",
                md: "70%",
              },
              textAlign: {
                xs: "start",
                md: "center",
              },
            }}
          >
            Company documents, Personal documents, Financial information, Social
            information Save via Digilocker or upload manually.
          </Typography>
          <img width="100%" src="/Landing4.webp" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1.5rem",
          }}
          ref={featureRef}
        >
          <Typography
            fontSize={"2.4rem"}
            fontWeight={"700"}
            lineHeight={"1.2"}
            width={"100%"}
            sx={{
              textAlign: {
                xs: "start",
                md: "center",
              },
            }}
          >
            Why SIFF?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: {xs: "100%", md: "50%"},
              }}
            >
              <Box
                sx={{
                  width: "150px",
                  background: "#d9d9d9",
                  color: "black",
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "4px 10px",
                }}
              >
                <Typography fontWeight={"700"} fontSize={"1rem"}>
                  For businesses
                </Typography>
              </Box>
              <Box
                sx={{display: "flex", flexDirection: "column", gap: "0.6rem"}}
              >
                {business.map((elem, idx) => {
                  return <CheckMark key={idx} text={elem} />;
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: {xs: "100%", md: "50%"},
              }}
            >
              <Box
                sx={{
                  width: "150px",
                  background: "#d9d9d9",
                  color: "black",
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "4px 10px",
                }}
              >
                <Typography fontWeight={"700"} fontSize={"1rem"}>
                  For Users
                </Typography>
              </Box>
              <Box
                sx={{display: "flex", flexDirection: "column", gap: "0.6rem"}}
              >
                {users.map((elem, idx) => {
                  return <CheckMark key={idx} text={elem} />;
                })}
              </Box>
            </Box>
          </Box>
          <Button
            // fullWidth
            onClick={() => {
              handleDownloadApp();
            }}
            sx={{
              margin: "0 auto",
              width: "240px",
              marginTop: "10px",
              bgcolor: "#617AEC",
              color: "white",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              py: "10px",
              transition: "box-shadow 0.5s ease", // Add transition for smooth effect
              "&:hover": {
                boxShadow: "0px 0px 10px 5px rgba(255, 255, 255, 0.3)",
              },
            }}
          >
            <Typography
              textTransform="none"
              fontSize={"16px"}
              fontWeight={500}
              color={"#d9d9d9"}
            >
              Get the App now
            </Typography>
            <Apple sx={{color: "#d9d9d9"}} />
            <img src="/playstore.svg" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "1.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1.5rem",
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <Typography
              fontSize={"2.4rem"}
              fontWeight={"700"}
              lineHeight={"1.2"}
              width={"100%"}
              onClick={handleBookDemo}
              sx={{cursor: "pointer"}}
            >
              Book a demo
            </Typography>
            <Typography fontSize={"1rem"}>
              SIFF helps you manage your leads and directly reach out to them on
              WhatsApp or Email Automate your sales cycle with just a click!
            </Typography>
            <Button
              // fullWidth
              onClick={handleBookDemo}
              sx={{
                margin: "0 0",
                width: "170px",
                marginTop: "10px",
                bgcolor: "#FFFFFF",
                color: "black",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                py: "10px",
                transition: "all 0.5s ease", // Add transition for smooth effect
                "&:hover": {
                  background: "white",
                },
              }}
            >
              <Typography
                textTransform="none"
                fontSize={"16px"}
                fontWeight={700}
                color={"#111111"}
              >
                Let’s connect
              </Typography>
              <ArrowOutward
                sx={{
                  transition: "all 1s ease",
                  "&:hover": {
                    transform: "rotate(360deg)",
                  },
                }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <img src="/Landing5.webp" width={"100%"} />
          </Box>
        </Box>
        <Box
          id="Footer"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "3rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              alignItems: {
                xs: "flex-start",
                md: "center",
              },
              justifyContent: "space-between",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography fontWeight={"800"} fontSize={"36px"}>
              SIFF{" "}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "inline",
                  },
                }}
              >
                - Single Interface for Forms
              </Box>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                // cursor: "pointer",
              }}
            >
              <Link
                href="https://www.instagram.com/siff.app/"
                sx={{textDecoration: "none", color: "white"}}
              >
                <Instagram
                  sx={{width: "32px", height: "32px", cursor: "pointer"}}
                />
              </Link>
              <Link
                href="https://twitter.com/Siffdotapp"
                sx={{textDecoration: "none", color: "white"}}
              >
                <Twitter
                  sx={{width: "32px", height: "32px", cursor: "pointer"}}
                />
              </Link>
              <Link
                href="mailto:contact@last2braincells.com?subject=I had to say something about your amazing product"
                sx={{textDecoration: "none", color: "white"}}
              >
                <Mail sx={{width: "32px", height: "32px", cursor: "pointer"}} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/singleinterfaceforforms/"
                sx={{textDecoration: "none", color: "white"}}
              >
                <LinkedIn
                  sx={{width: "32px", height: "32px", cursor: "pointer"}}
                />
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
              gap: "3rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              {/* <Typography fontSize={"16px"} color={"#d3d3d3"}>
                Pricing
              </Typography> */}
              <Typography
                fontSize={"16px"}
                color={"#d3d3d3"}
                sx={{cursor: "pointer"}}
                onClick={handleBookDemo}
              >
                Book a demo
              </Typography>
              {/* <Typography fontSize={"16px"} color={"#d3d3d3"}>
                Solutions
              </Typography> */}
              <Typography
                fontSize={"16px"}
                color={"#d3d3d3"}
                sx={{cursor: "pointer"}}
                onClick={() => {
                  handleDownloadApp();
                }}
              >
                Download
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Link
                sx={{textDecoration: "none", color: "white"}}
                href="https://www.linkedin.com/company/singleinterfaceforforms/"
              >
                <Typography
                  fontSize={"18px"}
                  fontWeight={"800"}
                  color={"#fff"}
                  sx={{cursor: "pointer"}}
                >
                  Company
                </Typography>
              </Link>
              <Typography
                sx={{cursor: "pointer"}}
                fontSize={"16px"}
                color={"#d3d3d3"}
                onClick={() => {
                  navigate("/aboutus");
                }}
              >
                About us
              </Typography>
              <Link
                sx={{textDecoration: "none", color: "white"}}
                href="https://www.linkedin.com/company/singleinterfaceforforms/jobs/"
              >
                <Typography
                  fontSize={"16px"}
                  color={"#d3d3d3"}
                  sx={{cursor: "pointer"}}
                >
                  Work with us
                </Typography>
              </Link>
              {/* <Typography fontSize={"16px"} color={"#d3d3d3"}>
                Contact us
              </Typography> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Typography
                fontSize={"12px"}
                fontWeight={"800"}
                color={"#848484"}
              >
                2024 SIFF, Inc All Rights Reserved.
              </Typography>
              <Box sx={{display: "flex", alignItems: "center", gap: "20px"}}>
                <Link
                  fontSize={"12px"}
                  color={"#848484"}
                  href="terms-and-conditions"
                >
                  Terms & conditions
                </Link>
                <Link
                  href="privacy-policies"
                  fontSize={"12px"}
                  color={"#848484"}
                >
                  Privacy Policy
                </Link>
                <Link href="refund-policy" fontSize={"12px"} color={"#848484"}>
                  Refund Policy
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Landing;
