interface Option {
  option: string;
  color: {
    r: number;
    g: number;
    b: number;
    a: number;
  }; // Assuming color is a number in the Color class
}

export interface Doc {
  name: string;
  type: "customDoc" | "siffDoc";
  required: boolean;
}

interface SubField {
  question: string;
  required: boolean;
  id?: string;
  parent: string;
  option: Option;
  tag: string;
}

interface FieldModel {
  question: string;
  required: boolean;
  inputType?: string; // Assuming 'Text' as a default value for inputType
  id?: string;
  options?: Option[];
  subFields?: SubField[];
  docs?: Doc[];
  isPhone?: boolean;
  upperLimit?: number;
  lowerLimit?: number;
  tag: string;
}

export default FieldModel;

export function isFieldModel(data: {[key: string]: any}): data is FieldModel {
  return data.question !== undefined;
}
