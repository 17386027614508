import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {collection, getDocs} from "firebase/firestore";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {app, db} from "src/firebaseConfig";
import "./Stats.css";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import toast from "react-hot-toast";
import Navbar from "src/Components/Global/Navbar";

export default function Stats() {
  const [users, setUsers] = useState(-1);
  const [forms, setForms] = useState(-1);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      }
    });

    const fetchData = async () => {
      try {
        const formsCollection = collection(db, "forms");
        const usersCollection = collection(db, "users");

        const [formsData, usersData] = await Promise.all([
          getDocs(formsCollection),
          getDocs(usersCollection),
        ]);
        setForms(formsData.size);
        setUsers(usersData.size);
      } catch (error) {
        console.error("Error getting data:", error);
      }
    };

    fetchData();
    const handleBodyClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null;
      if (target && !target.closest("#avatar") && !target.closest("#menu")) {
        setOpenMenu(false);
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  function handleOpen() {
    const hamburger = document.getElementById("hamburger") as HTMLElement;
    hamburger.classList.add("toggle");
    setOpen(true);
  }

  function handleClose() {
    const hamburger = document.getElementById("hamburger") as HTMLElement;
    hamburger.classList.remove("toggle");
    setOpen(false);
  }

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  function handleLogout() {
    const auth = getAuth(app);
    auth.signOut().then(() => {
      toast.success("Logged Out");
      console.log("Logged out");
      handleClose();
      setLoggedIn(false);
    });
  }

  function handleBookDemo() {
    const imagine_link = document.createElement("a");
    imagine_link.href = "https://calendly.com/last2braincells/siff-demo-call";
    imagine_link.click();
    imagine_link.remove();
  }

  function handleDownloadApp() {
    const download_link = document.createElement("a");
    download_link.href =
      "https://play.google.com/store/apps/details?id=com.l2bc.siff";
    download_link.click();
    download_link.remove();
  }

  return (
    <Box
      width={["90%", "80%", "50%", "35%"]}
      sx={{display: "flex", flexDirection: "column", gap: "1rem", mb: "20px"}}
    >
      <Navbar />
      <Box
        sx={{
          display: open ? "none" : "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography fontSize={"1rem"}>SIFF stats</Typography>
          <FormControl sx={{m: 1, minWidth: 120}} size="small" disabled>
            <InputLabel id="demo-select-small-label">All time</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              // value={age}
              label="Age"
              autoWidth
              // onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "110px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#161616",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"12px"} id="totalUsers">
              Total users
            </Typography>
            <Typography fontSize={"32px"} fontWeight={"700"} id="totalUsers">
              {users == -1 ? "-" : users}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "110px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#161616",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"12px"}>App downloads</Typography>
            <Typography fontSize={"32px"} id="totalDownloads">
              -
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "10px",
          }}
        >
          <Typography fontSize={"1rem"}>Forms</Typography>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Total forms created</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="totalForms">
                {forms == -1 ? "-" : forms}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Total submissions</Typography>
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                id="totalSubmissions"
              >
                -
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "10px",
          }}
        >
          <Typography fontSize={"1rem"}>Activity</Typography>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>APP MAU</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="AppMau">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Web MAU</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="WebMau">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>MAF</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="MAF">
                -
              </Typography>
            </Box>
          </Box>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"} id="AppDau">
                APP DAU
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"} id="WebDau">
                Web DAU
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"} id="DAF">
                DAF
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                -
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "10px",
          }}
        >
          <Typography fontSize={"1rem"}>Non-App user</Typography>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Temp users</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="tempUsers">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Web users</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="webUsers">
                -
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "10px",
          }}
        >
          <Typography fontSize={"1rem"}>App users</Typography>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>App users</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="appUsers">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Local users</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="localUsers">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Verified users</Typography>
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                id="verifiedUsers"
              >
                -
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "10px",
          }}
        >
          <Typography fontSize={"1rem"}>Premium users</Typography>
          <Box sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Starter plan</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="starterPlan">
                -
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#161616",
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"10px"}>Pro plan</Typography>
              <Typography fontSize={"16px"} fontWeight={"500"} id="proPlan">
                -
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
