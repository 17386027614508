export default async function imageToText(image: File): Promise<string> {
  const apiKey = import.meta.env.VITE_CLOUD_VISION_KEY;

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (ev) => {
      const fileString = ev.target!.result as string;

      const requestData = {
        requests: [
          {
            image: {
              content: fileString.split(",")[1], // Extracting base64 content
            },
            features: [{type: "TEXT_DETECTION", maxResults: 10}],
          },
        ],
      };

      const config = {
        method: "POST",
        body: JSON.stringify(requestData),
      };

      fetch(
        "https://vision.googleapis.com/v1/images:annotate?key=" + apiKey,
        config,
      )
        .then((apiCall) => apiCall.json())
        .catch((e) => console.log(e))
        .then((response) => {
          const text = response.responses[0].fullTextAnnotation.text;
          resolve(text);
        })
        .catch((error) => reject(error));
    };

    fileReader.readAsDataURL(image);
  });
}
