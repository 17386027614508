import FieldModel from "src/Models/Form/FieldModel";
import ImageModel from "src/Models/Form/ImageModel";
import TextModel from "src/Models/Form/TextModel";

export interface Page {
  name: string;
  description: string;
  fields: (FieldModel | ImageModel | TextModel)[];
}

export function isPage(field: {[key: string]: any}): field is Page {
  return field.name !== undefined;
}

interface FormModel {
  id?: string;
  owner?: string;
  title: string;
  description: string;
  fields: Page[] | (FieldModel | ImageModel | TextModel)[];
  image?: string | null;
  createdAt?: Date | null;
  authSetting?: string | null;
  cover?: string | null;
  multiPage: boolean;
  open: boolean;
  sendResponse: boolean;
  limitOneResponse: boolean;
  allowEdit: boolean;
  accessType: string;
  openFrom?: Date | null;
  expiryDate?: Date | null;
  showExpiryDate: boolean;
  responseLimit?: number | null;
  showResponsesLeft: boolean;
  ageLimit?: number[] | null;
  verifiedOnly: boolean;
  admins: string[];
  confMessage?: string | null;
  updatedAt?: Date | null;
  qr: string;
}

export default FormModel;
