import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";
import {FormField} from "src/Components/FormFillup";
import UserDataField from "src/Components/FormFillup/UserDataField";
// import Ad from "src/Components/Global/Ad";
import FieldModel, {isFieldModel} from "src/Models/Form/FieldModel";
import FormModel, {Page, isPage} from "src/Models/Form/Form";
import ImageModel from "src/Models/Form/ImageModel";
import TextModel from "src/Models/Form/TextModel";
import {isImageModel} from "src/Utils/Form/isImageModel";
import {isTextModel} from "src/Utils/Form/isTextModel";
import submitForm from "src/Utils/Form/submitForm";
import {app, db} from "src/firebaseConfig";
import PersonalDetails from "./PersonalDetails";
import {Helmet} from "react-helmet-async";

/**
 * Represents a submission object.
 * @interface
 */
export interface ISubmission {
  [key: string]: any;
}

export interface Errors {
  [key: string]: boolean;
}

export interface UserData {
  name?: string;
  phone?: string;
  refId?: string;
}

/**
 * Fetches the form data based on the given formId and displays the form with its fields.
 * On form submission, adds the user's answers to the form's submissions collection in the database.
 * If the user is not authenticated, redirects to the temporary sign-in page.
 */
export default function FormFillup() {
  const [skipAuthentication, setSkipAuthentication] = useState(false);
  const [submission, setSubmission] = useState<ISubmission>({});
  const [form, setForm] = useState<FormModel>();
  const [page, setPage] = useState(0);
  const [ownerName, setOwnerName] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [files, setFiles] = useState<{[key: string]: File}>({});
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [submissionData, setSubmissionData] = useState<ISubmission>({});
  const [authSetting, setAuthSetting] = useState<string | undefined>(undefined);
  const [answers, setAnswers] = useState<ISubmission | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>();
  const tags = useRef<{[key: string]: FieldModel}>({});
  const {formId} = useParams();
  const navigate = useNavigate();
  const navigated = useRef(false);
  const maxPage = useRef(0);
  const stepRef = useRef<HTMLDivElement | null>(null);
  const userData = useRef<UserData | null>(null);
  const firstnameId = useRef<string>();
  const lastnameId = useRef<string>();
  const phoneId = useRef<string>();
  const nameRef = useRef<HTMLInputElement>();
  const phoneRef = useRef<HTMLInputElement>();
  const userDataElements = useMemo(() => {
    const elements: ReactElement[] = [];
    function getField(tags: string[]): FieldModel | undefined {
      let fields = form?.fields;
      if (form?.multiPage) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        fields = form.fields[0].fields;
      }
      const filtered = fields?.find((element) => {
        if (isFieldModel(element)) {
          return tags.includes(element.tag);
        }
        return false;
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return filtered;
    }
    if (form && form?.authSetting == "Do not ask") {
      const fullnameField = getField(["name", "full_name", "first_name"]);
      const lastnameField = getField(["last_name"]);
      const phoneField = getField(["phone", "mobile_number", "mobile"]);

      if (fullnameField) {
        firstnameId.current = fullnameField?.id;
      } else {
        elements.push(
          <UserDataField
            name="Full name"
            onChange={(e) => (nameRef.current = e.target)}
          />,
        );
      }
      if (phoneField) {
        phoneId.current = phoneField?.id;
      } else {
        elements.push(
          <UserDataField
            name="Phone"
            onChange={(e) => (phoneRef.current = e.target)}
          />,
        );
      }
      lastnameId.current = lastnameField?.id;
    }
    return elements;
  }, [form]);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "users", user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.data();
            const firstname = data.firstname;
            const lastname = data.lastname;
            setAnswers({
              ...answers,
              firstname,
              lastname,
              full_name: `${firstname} ${lastname}`,
              phone_number: user.phoneNumber,
              phone: user.phoneNumber,
              personal_phone: user.phoneNumber,
              email: data.email,
              name: `${firstname} ${lastname}`,
            });
          }
        });
        setUserId(user.uid);
        setLoggedIn(true);
      }
    });

    if (formId && formId.length === 20) {
      dayjs.extend(customParseFormat);
      const formRef = doc(db, "forms", formId);
      getDoc(formRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.data();
            const fields = JSON.parse(data.fields) as
              | Page[]
              | (FieldModel | ImageModel | TextModel)[];
            if (data.output) {
              const newTags = JSON.parse(
                (data.output as string)
                  .replaceAll("`", "")
                  .replaceAll("json", "")
                  .replaceAll("JSON", ""),
              ) as {[key: string]: string};
              for (const field of fields) {
                if (isPage(field)) {
                  for (const pageField of field.fields) {
                    if (isFieldModel(pageField)) {
                      pageField.tag = newTags[pageField.question];
                      tags.current[pageField.tag] = pageField;
                    }
                  }
                } else if (isFieldModel(field)) {
                  field.tag = newTags[field.question];
                  tags.current[field.tag] = field;
                }
              }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            setForm({...data, fields});
            if (data.authSetting == "Do not ask") {
              setSkipAuthentication(true);
            }
            setAuthSetting(data.authSetting);

            const ownerRef = doc(db, "users", data.owner);
            getDoc(ownerRef).then((ownerSnapshot) => {
              if (ownerSnapshot.exists()) {
                const ownerData = ownerSnapshot.data();
                setSubmissionData({
                  ...submissionData,
                  title: data.title,
                  description: data.description,
                  confMessage: data.confMessage,
                  image: data.image,
                  ownerUsername: ownerData.username,
                  ownerProfile: ownerData.profilePic,
                  ownerName: `${ownerData.firstname} ${ownerData.lastname}`,
                  formId: formId,
                });
                setOwnerName(`${ownerData.firstname} ${ownerData.lastname}`);
              }
            });
          } else {
            console.error("No such document!");
          }
          setShowPage(true);
        })
        .catch((error) => {
          console.error("Error getting document:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const answersCollection = collection(doc(db, "users", userId), "answers");
      getDoc(doc(answersCollection, userId)).then((snapshot) => {
        if (snapshot.exists()) {
          setAnswers(snapshot.data());
        }
      });
    }
  }, [userId]);

  // Auto fill
  useEffect(() => {
    if (form) {
      try {
        const json: ISubmission = {};
        if (formId) {
          const savedSubmission = localStorage[formId]
            ? JSON.parse(localStorage[formId])
            : {};
          const localAnswers = localStorage.answers
            ? JSON.parse(localStorage.answers)
            : {};
          for (const key of Object.keys(tags.current)) {
            const field = tags.current[key];
            if (localAnswers[field.tag]) {
              json[field.id!] = localAnswers[field.tag];
            }
            if (savedSubmission[field.id!]) {
              json[field.id!] = savedSubmission[field.id!];
            }
            if (answers) {
              const answer = answers[key];
              if (!json[key]) {
                if (answer instanceof Timestamp) {
                  json[field.id!] = dayjs(answer.toDate());
                } else {
                  if (
                    field.inputType === "Drop down" ||
                    field.inputType === "Multiple choice"
                  ) {
                    const option = field.options!.filter(
                      (elem) => elem.option == answer,
                    );

                    if (option.length > 0) {
                      json[field.id!] = answers[key];
                    } else if (json[field.id!]) {
                      const currAnswer = json[field.id!];
                      const option = field.options!.filter(
                        (elem) => elem.option === currAnswer,
                      );
                      if (option.length === 0) {
                        json[field.id!] = undefined;
                      }
                    }
                    continue;
                  } else if (field.inputType === "Checkbox") {
                    if (answer instanceof Array) {
                      const availableAnswers = answer.filter(
                        (elem) =>
                          field.options!.filter(
                            (option) => option.option === elem,
                          ).length == -1,
                      );
                      if (availableAnswers.length > 0) {
                        json[field.id!] = availableAnswers;
                      } else if (json[field.id!]) {
                        const newAnswers = answer.filter(
                          (elem) =>
                            field.options!.filter(
                              (option) => option.option === elem,
                            ).length > 0,
                        );
                        json[field.id!] = newAnswers;
                      }
                      continue;
                    }
                  }
                  json[field.id!] = answers[key];
                }
              }
            }
          }
          for (const key of Object.keys(json)) {
            const value = json[key] as string;
            const date = dayjs(value);
            if (date.isValid() && value.length > 20) {
              json[key] = date;
            }
          }
        }
        setSubmission({...submission, ...json});
      } catch (e) {
        console.error(e);
      }
    }
  }, [form, answers]);

  useEffect(() => {
    const gifTimeout = setTimeout(() => {
      setShowGif(false);
    }, 3000);

    return () => clearTimeout(gifTimeout);
  }, [showGif]);

  function cleanData(field: FieldModel, cleanedSubmission: ISubmission) {
    if (field.inputType === "Date" || field.inputType === "Time") {
      const value = cleanedSubmission[field.id ?? field.question];
      if (value) {
        if (dayjs.isDayjs(value)) {
          cleanedSubmission[field.id ?? field.question] = value.toDate();
        }
      }
    } else if (field.inputType === "Document") {
      for (const docField of field.docs!) {
        const value = cleanedSubmission[docField.name];
        const file = files[docField.name];
        if (value && file) {
          cleanedSubmission[docField.name] = file;
        }
      }
    }
  }

  function validate(currFields: (FieldModel | ImageModel | TextModel)[]) {
    let isValid = true;
    const errorField = [];
    for (const field of currFields) {
      if (!isImageModel(field) && !isPage(field) && !isTextModel(field)) {
        if (field.required && field.inputType != "Document") {
          const currField = field.id ?? field.question;
          if (
            !submission[currField] ||
            submission[currField] == undefined ||
            submission[currField].length == 0
          ) {
            for (const htmlField of fields) {
              if (htmlField.props.field.question == currField) {
                errorField.push(currField);
                isValid = false;
              }
            }
          }
        }
      }
    }

    for (const error of errorField) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [error]: true,
        };
      });
    }
    return isValid;
  }

  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    if (!form?.multiPage || page == form.fields.length - 1) {
      let fieldsNew: (FieldModel | ImageModel | TextModel)[] = [];
      const cleanedSubmission = {...submission};
      let isValid: boolean;

      if (!form!.multiPage) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        fieldsNew = fieldsNew.concat(form!.fields);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        isValid = validate(form!.fields);
      } else {
        isValid = validate((form!.fields[page] as Page).fields);
      }
      if (!isValid) {
        setLoading(false);
        return;
      }

      for (const field of form!.fields) {
        if (isFieldModel(field)) {
          cleanData(field, cleanedSubmission);
        } else if (isPage(field)) {
          fieldsNew = fieldsNew.concat(field.fields);
          for (const pageField of field.fields) {
            if (isFieldModel(pageField)) {
              cleanData(pageField, cleanedSubmission);
            }
          }
        }
      }

      // for custom validation of required fields which don't have any required things

      const auth = getAuth(app);
      if (skipAuthentication) {
        if (authSetting == "Do not ask") {
          if (firstnameId.current) {
            const firstname = submission[firstnameId.current] as string;
            userData.current = {...userData.current, name: firstname};
          } else {
            userData.current = {
              ...userData.current,
              name: nameRef.current!.value,
            };
          }
          if (phoneId.current) {
            userData.current = {
              ...userData.current,
              phone: submission[phoneId.current],
            };
          } else {
            userData.current = {
              ...userData.current,
              phone: phoneRef.current!.value,
            };
          }
          if (lastnameId.current) {
            userData.current = {
              ...userData.current,
              name: `${userData.current?.name} ${
                submission[lastnameId.current]
              }`,
            };
          }
        }
        let user: string;
        const q = await getDocs(
          query(
            collection(db, "tempUsers"),
            where("phone", "==", userData.current!.phone),
          ),
        );
        if (q.docs.length > 0) {
          user = q.docs[0].id;
        } else {
          user = (await addDoc(collection(db, "tempUsers"), userData.current))
            .id;
        }
        submitForm(cleanedSubmission, formId!, user, fieldsNew, true)
          .then((obj) => {
            userData.current!.refId = user;
            navigate("/submission", {
              state: {submissionData, obj, tempUser: userData.current},
            });
          })
          .catch((err) => {
            addDoc(collection(db, "errors"), {
              component: "FormFillup",
              error: err,
              form: formId,
            });
            console.error(err);
            toast.error(
              "There was an error submitting your form please try again",
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        onAuthStateChanged(auth, (user) => {
          if (user && !navigated.current) {
            submitForm(cleanedSubmission, formId!, user.uid, fieldsNew)
              .then((obj) => {
                console.log("done");

                navigate("/submission", {
                  state: {submissionData, obj},
                });
              })
              .catch((err) => {
                console.error(err);
                toast.error(
                  "There was an error submitting your form please try again",
                );
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });
      }
    } else if (page < form.fields.length - 1) {
      setLoading(false);
      // for custom validation of required fields which don't have any required things
      const isValid = validate((form!.fields[page] as Page).fields);
      if (!isValid) {
        return;
      }

      setPage(page + 1);
      maxPage.current = maxPage.current + 1;
      if (stepRef.current) {
        const stepperRef = stepRef.current.querySelector(`#step-${page}`);
        if (stepperRef) {
          stepperRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }
      }
    }
  }

  let fields: ReactElement[] = [];
  const currPage = form?.fields[page];
  if (currPage) {
    if (isPage(currPage)) {
      fields = currPage.fields.map((element) => {
        let key: string;
        if (isImageModel(element)) {
          key = element.url;
        } else if (isTextModel(element)) {
          key = element.text;
        } else {
          key = element.id ?? element.question;
        }
        return (
          <FormField
            field={element}
            submission={submission}
            setSubmission={setSubmission}
            owner={ownerName}
            errors={errors}
            setErrors={setErrors}
            files={files}
            setFiles={setFiles}
            key={key}
            disable={
              !loggedIn && !skipAuthentication && authSetting != "Do not ask"
            }
            formId={formId ?? ""}
          />
        );
      });
    } else if (!isPage(currPage)) {
      fields = form?.fields.map((element, i) => {
        if (!isPage(element)) {
          return (
            <FormField
              field={element}
              submission={submission}
              setSubmission={setSubmission}
              errors={errors}
              setErrors={setErrors}
              owner={ownerName}
              files={files}
              setFiles={setFiles}
              key={i}
              disable={!loggedIn && !skipAuthentication}
              formId={formId ?? ""}
            />
          );
        }
        return <></>;
      });
    }
  }

  if (!showPage) {
    return null;
  }

  return (
    <>
      <Helmet>
        {formId == "Lij6Tf7gOAvMs7rhojF0" && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-23CYGK5B29"
            ></script>
            <script>
              {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-23CYGK5B29');`}
            </script>
          </>
        )}
        <title>{form?.title}</title>
        <meta property="og:title" content={form?.title} />
        <meta property="og:description" content={form?.description} />
        {form?.cover && <meta property="og:image" content={form?.cover} />}
      </Helmet>
      <Box
        width={["90%", "80%", "50%", "35%"]}
        display="flex"
        flexDirection="column"
        paddingTop="10px"
        paddingBottom="4rem"
      >
        <CssBaseline />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "1.5rem",
            mt: "0.5rem",
          }}
        >
          <img
            src={"/Logo2.svg"}
            style={{cursor: "pointer", width: "120px"}}
            onClick={() => {
              navigate("/");
            }}
          />
        </Box>
        {/* <Ad adSlot="7047796441" /> */}
        <Box
          paddingBottom="1rem"
          sx={{display: "flex", flexDirection: "column"}}
        >
          <Typography variant="h3">{form?.title}</Typography>
          {form?.cover && (
            <img
              src={form.cover}
              width="100%"
              style={{
                borderRadius: "25px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              alt="formCover"
            />
          )}
          <Typography paddingTop="5px">{form?.description}</Typography>
        </Box>
        {form?.multiPage && (
          <Box>
            <Stepper
              activeStep={page}
              ref={stepRef}
              sx={{
                whiteSpace: "nowrap",
                overflowY: "hidden",
                height: "50px",
                overflowX: "auto",
                WebkitOverflowScrolling: "touch",
                "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                  borderColor: "rgb(144,202,249)",
                },
                "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
                  {
                    borderColor: "rgb(144,202,249)",
                  },
              }}
              connector={
                <StepConnector
                  sx={{
                    minWidth: "50px",
                    background: "white",
                  }}
                ></StepConnector>
              }
            >
              {form?.fields.map((element, i) => {
                if (isPage(element)) {
                  return (
                    <Step
                      key={i}
                      onClick={() => {
                        if (i <= maxPage.current) {
                          setPage(i);
                        }
                      }}
                      id={`step-${i}`}
                    >
                      <StepLabel sx={{cursor: "pointer"}}>
                        {element.name}
                      </StepLabel>
                    </Step>
                  );
                }
              })}
            </Stepper>
          </Box>
        )}
        {loggedIn || skipAuthentication
          ? showGif && (
              <Box
                sx={{
                  width: "100%",
                  background: "#262626",
                  padding: "1rem",
                  borderRadius: "15px",
                  marginTop: "10px",
                }}
              >
                <img
                  alt="gif"
                  src="/submitted.gif"
                  style={{width: "100%", height: "100%", objectFit: "cover"}}
                />
              </Box>
            )
          : //New box added before the form submission
            authSetting != "Do not ask" && (
              <PersonalDetails
                setSkipAuthentication={setSkipAuthentication}
                setShowGif={setShowGif}
                userData={userData}
                authSetting={authSetting}
              />
            )}
        <Box
          component="form"
          marginTop="10px"
          marginBottom="2vw"
          alignSelf="center"
          width="100%"
          onSubmit={submit}
        >
          {userDataElements}
          {fields}
          {/* <Ad adSlot="7420165682" /> */}
          <Box
            position="fixed"
            bgcolor="#4361EE"
            paddingY="1rem"
            display="flex"
            justifyContent="space-between"
            paddingX="10px"
            bottom={0}
            margin="auto"
            width={["100%", "80%", "50%", "36%"]}
            left={["0%", "9%", "25%", "32%"]}
          >
            <Button
              sx={{borderRadius: "25px", paddingX: "1rem"}}
              onClick={() => setSubmission({})}
            >
              <Typography textTransform="none" color="white">
                Discard
              </Typography>
            </Button>
            <Button
              disabled={loading || (!loggedIn && !skipAuthentication)}
              type="submit"
              sx={{
                bgcolor: "black",
                borderRadius: "25px",
                paddingX: "1rem",
                "&:hover": {
                  backgroundColor: "black",
                },
                "&:disabled": {
                  cursor: "not-allowed",
                },
              }}
            >
              {loading ? (
                <CircularProgress size="25px" color="inherit" />
              ) : (
                <>
                  <Typography textTransform="none" color="white">
                    {!form?.multiPage || page == form.fields.length - 1
                      ? "Submit"
                      : "Next"}
                  </Typography>
                  <ArrowForwardIcon sx={{color: "white"}} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
