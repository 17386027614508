import {Box, Button, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Markdown from "react-markdown";
import {styled} from "@mui/material/styles";

import "./blog.css";
// import BlueButton from "src/Components/Global/BlueButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {getDownloadURL, uploadBytes, ref} from "firebase/storage";
import {storage, db} from "src/firebaseConfig";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {Blog} from "./Interfaces";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function AddBlog() {
  const [content, setContent] = useState("");
  const [file, setFile] = useState<null | File | {name: string}>(null);
  const [title, setTitle] = useState("");
  const [imgLink, setImgLink] = useState("");
  const [writer, setWriter] = useState("");
  const [id, setId] = useState<string | null>("");
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loadPage, setLoadPage] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const blogCollectionRef = collection(db, "blog");
        const querySnapshot = await getDocs(blogCollectionRef);

        const blogData: Blog[] = [];
        querySnapshot.forEach((doc) => {
          const blog: Blog = {id: doc.id, ...doc.data()} as Blog;
          blogData.push(blog);
        });

        blogData.forEach((entry) => {
          if (typeof entry.createdAt !== "string") {
            const timestamp = entry.createdAt;
            const date = new Date(
              timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
            );
            const options: Intl.DateTimeFormatOptions = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            entry.createdAt = date.toLocaleDateString("en-US", options);
          }
        });
        setBlogs(blogData);
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loadPage]);

  function changeBlog(id: string) {
    const currBlog: Blog = blogs?.filter((elem: Blog) => {
      return elem.id == id;
    })[0];
    setId(currBlog.id);
    setTitle(currBlog?.title.split("-").join(" "));
    setWriter(currBlog.writer);
    setFile({
      name: "bannerImage.jpg",
    });
    setImgLink(currBlog.headImage);
    setContent(currBlog.content);
  }

  async function uploadFile(currFile: File) {
    try {
      const storageRef = ref(storage, `blogImages/${currFile.name}`);
      await uploadBytes(storageRef, currFile);
      const filePath = await getDownloadURL(storageRef);
      setImgLink(filePath);
    } catch (err) {
      console.log(err);
      toast.error("There was an error uploading your image ", {id: "blog"});
    }
  }

  function handleCreateBlog() {
    setId(null);
    setTitle("");
    setWriter("");
    setFile(null);
    setImgLink("");
    setContent("");
  }

  // function handleWriterChange() {
  //   const currDate = dayjs().format("MMMM D, YYYY");
  //   const writerName = `**${writer}** | ${currDate}`;
  //   const updatedContent = `\n\n${writerName}\n\n ${content}`;
  //   setContent(updatedContent);
  // }

  function truncateFileName(fullFileName: string): string {
    const maxLength = 10;
    if (fullFileName.length > maxLength) {
      const fileExtension = fullFileName.split(".").pop();

      const truncatedLength = maxLength - fileExtension!.length - 1;

      const truncatedFileName =
        fullFileName.substring(0, truncatedLength) + "..." + fileExtension;

      return truncatedFileName;
    }

    return fullFileName;
  }

  async function deleteBlog() {
    const confirmation = confirm("Are you sure you want to delete?");
    if (confirmation && id) {
      try {
        const blogCollectionRef = doc(db, "blog", id);
        await deleteDoc(blogCollectionRef);
        toast.success("Blog deleted");
        handleCreateBlog();
        setId(null);
        setLoadPage(!loadPage);
      } catch (err) {
        console.log(err);
        toast.error("Something went wrong");
      }
    } else {
      return;
    }
  }

  async function handlePublish() {
    try {
      if (title.length == 0) {
        toast.error("Please Enter title");
        return;
      }
      if (writer.length == 0) {
        toast.error("Please enter writer's name");
        return;
      }
      if (imgLink.length == 0) {
        toast.error("Please upload banner image");
        return;
      }

      if (id) {
        const blogCollectionRef = doc(db, "blog", id);

        let updatedContent = content;

        // Replace title
        updatedContent = updatedContent.replace(/#.*$/, `# ${title}`);

        // Replace writer
        updatedContent = updatedContent.replace(
          /\*\*[^*]+\*\*/,
          `**${writer}**`,
        );

        // Replace image link
        updatedContent = updatedContent.replace(/\(([^)]+)\)/g, `(${imgLink})`);

        const newTitle = title
          .replace(/[^\p{L}\s]/gu, "")
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .join("-");

        await updateDoc(blogCollectionRef, {
          title: newTitle,
          headImage: imgLink,
          writer,
          content: updatedContent,
          createdAt: Timestamp.now(),
        });
        handleCreateBlog();
        toast.success("Blog updated");
        setLoadPage(!loadPage);
        return;
      }

      const currDate = dayjs().format("MMMM D, YYYY");
      const writerName = `**${writer}** | ${currDate}`;

      const updatedContent = `![Banner-Image](${imgLink})\n\n${writerName}\n\n# ${title}\n\n${content}`;

      const blogCollectionRef = collection(db, "blog");

      const newTitle = title
        .replace(/[^\p{L}\s]/gu, "")
        .replace(/\s+/g, " ")
        .trim()
        .split(" ")
        .join("-");

      const newBlogRef = await addDoc(blogCollectionRef, {
        title: newTitle,
        headImage: imgLink,
        writer,
        content: updatedContent,
        createdAt: Timestamp.now(),
      });
      toast.success("blog published");
      setContent("");
      setWriter("");
      setTitle("");
      setImgLink("");
      setFile(null);
      setLoadPage(!loadPage);
      handleCreateBlog();
      console.log("Blog published", newBlogRef.id);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong", {id: "blog"});
    }
  }

  return (
    <>
      {!loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography sx={{fontSize: "3rem", marginBottom: "1rem"}}>
            Add Blog
          </Typography>
          <Box sx={{display: "flex", gap: "3rem", width: "100%"}}>
            <Box
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                background: "#2d2d2d",
                py: "2rem",
                borderRadius: "10px",
                px: "10px",
              }}
            >
              <Typography sx={{fontSize: "1.5rem", textAlign: "center"}}>
                Blogs
              </Typography>
              <Button variant="contained" onClick={handleCreateBlog}>
                Add new blog +
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  gap: "10px",
                }}
              >
                {blogs?.map((elem, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        py: "10px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        background: id == elem.id ? "#4d4d4d" : "#2d2d2d",
                        "&:hover": {
                          background: "#4d4d4d",
                        },
                      }}
                      onClick={() => {
                        changeBlog(elem.id);
                      }}
                    >
                      <Typography
                        sx={{fontSize: "16px", width: "80%", margin: "0 auto"}}
                      >
                        {elem.title.split("-").join("  ")}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", width: "50%"}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  my: "10px",
                }}
              >
                <TextField
                  required
                  variant="outlined"
                  name="Title"
                  value={title}
                  label="Title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  sx={{pr: "0px"}}
                />
                <TextField
                  required
                  value={writer}
                  variant="outlined"
                  name="writer name"
                  label="Writer name"
                  onChange={(e) => {
                    setWriter(e.target.value);
                  }}
                  sx={{pr: "0px"}}
                />
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  sx={{fontSize: "12px", whiteSpace: "nowrap"}}
                >
                  {file ? truncateFileName(file?.name) : " Upload Banner"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      if (e.target.files !== null) {
                        setFile(e.target.files[0] as File);
                        uploadFile(e.target.files[0] as File);
                      } else {
                        toast.error("Something went wrong, Please try again");
                      }
                    }}
                  />
                </Button>
              </Box>
              <textarea
                style={{
                  width: "100%",
                  height: "78vh",
                  padding: "1rem",
                  resize: "none",
                  fontSize: "1.2rem",
                }}
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                gap: "1rem",
              }}
            >
              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Typography
                  sx={{fontSize: "2rem", textAlign: "center", mb: "10px"}}
                >
                  Preview
                </Typography>
                {id && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={deleteBlog}
                  >
                    Delete
                  </Button>
                )}
                <Button variant="contained" onClick={handlePublish}>
                  Publish
                </Button>
              </Box>
              <Markdown className={"markdown"}>{content}</Markdown>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
