class DocInfo {
  name: string;
  description: string;
  icon: string;
  issuer: string;
  federalSystem: string;
  text?: string;
  regex?: string;
  number?: string;
  photos: (File | null)[];
  verified: boolean = false;

  constructor({
    name,
    description,
    icon,
    issuer,
    federalSystem,
    photos,
    regex,
  }: {
    name: string;
    description: string;
    icon: string;
    issuer: string;
    federalSystem: string;
    photos: (File | null)[];
    regex?: string;
  }) {
    this.name = name;
    this.description = description;
    this.icon = icon;
    this.issuer = issuer;
    this.federalSystem = federalSystem;
    this.photos = photos;
    this.regex = regex;
  }
}

const documents: DocInfo[] = [
  new DocInfo({
    name: "Aadhaar",
    description:
      "Aadhaar is a 12 digit individual identification number which serves as proof of identity and proof of address for residents of India.",
    icon: "./aadhaar.png",
    issuer: "Unique Identification Authority of India (UIDAI)",
    federalSystem: "Central govt.",
    photos: Array(2).fill(null),
    regex: "\\d{4}\\s\\d{4}\\s\\d{4}",
  }),
  new DocInfo({
    name: "PAN",
    description:
      'Permanent Account Number (PAN) is a ten-digit alphanumeric number, issued in the form of a laminated card, by the Income Tax Department, to any "person" who applies for it or to whom the department allots the number.',
    icon: "./pan.png",
    issuer: "Income Tax Department",
    federalSystem: "Central govt.",
    photos: Array(1).fill(null),
    regex: "[A-Z]{5}\\d{4}[A-Z]",
  }),
  new DocInfo({
    name: "Board certificate",
    description:
      "The CBSE School Certificate is a nationally recognized credential awarded by the Central Board of Secondary Education in India. It signifies the successful completion of secondary education, demonstrating proficiency in a standardized curriculum. Accepted widely for further education, it attests to a student's academic achievements and competency in key subjects.",
    icon: "./boardCertificate.png",
    issuer: "Central Board of Secondary Educatio2n in India",
    federalSystem: "Central govt.",
    photos: Array(1).fill(null),
  }),
  new DocInfo({
    name: "Driving license",
    description:
      "A driving license in India is an official permit issued by regional transport authorities, allowing individuals to drive on public roads. Obtained through written and practical tests, it signifies the holder's compliance with traffic rules and ability to operate specific vehicle types. Validity and renewal depend on the license category.",
    icon: "./dl.png",
    issuer: "Regional transport authorities",
    federalSystem: "State govt.",
    photos: Array(2).fill(null),
    regex: "[A-Z]{2}-[0-9]{13}",
  }),
  new DocInfo({
    name: "Passport",
    description:
      "An Indian passport is a key travel document issued by the Government of India, verifying the identity and nationality of the holder. Essential for international travel, it contains personal details, a photo, and a signature. Valid for 10 years for adults and 5 years for minors, the application process involves document submission and biometric data collection. It serves as proof of identity and address domestically as well.",
    icon: "./passport.png",
    issuer: "Government of India",
    federalSystem: "State govt.",
    photos: Array(2).fill(null),
    regex: "[A-Z]{1}[0-9]{7}",
  }),
  new DocInfo({
    name: "Voter ID",
    description:
      "The Indian voter ID card, or Electors Photo Identity Card (EPIC), is a key document issued by the Election Commission, serving as proof of identity for eligible citizens to participate in democratic elections.",
    icon: "./voter.png",
    issuer: "Government of India",
    federalSystem: "Central Govt.",
    photos: Array(1).fill(null),
  }),
];

export default documents;
