import {Box, Typography} from "@mui/material";
import {collection, getDocs} from "firebase/firestore";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {db} from "src/firebaseConfig";
import {Blog} from "./Interfaces";
import Navbar from "src/Components/Global/Navbar";

export default function AllBlog() {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const blogCollectionRef = collection(db, "blog");
        const querySnapshot = await getDocs(blogCollectionRef);

        const blogData: Blog[] = [];
        querySnapshot.forEach((doc) => {
          const blog: Blog = {id: doc.id, ...doc.data()} as Blog;
          blogData.push(blog);
        });
        blogData.forEach((entry) => {
          if (typeof entry.createdAt !== "string") {
            const timestamp = entry.createdAt;
            const date = new Date(
              timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
            );
            const options: Intl.DateTimeFormatOptions = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            entry.createdAt = date.toLocaleDateString("en-US", options);
          }
        });

        setBlogs(blogData);
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {!loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Navbar />
          {/* <Typography sx={{fontSize: "2rem", fontWeight: "700"}}>
            Blogs
          </Typography> */}
          {blogs.length == 0 && (
            <Typography
              sx={{fontSize: "1.5rem", fontWeight: "400", mt: "3rem"}}
            >
              Sorry, there are no blogs available at the moment. Check back
              later for updates!
            </Typography>
          )}
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "grid",
              },
              flexDirection: "column",
              gridTemplateColumns: "1fr 1fr",
              gap: "2rem",
              width: {
                xs: "90vw",
                md: "80vw",
                lg: "70vw",
              },
              py: "2rem",
            }}
          >
            {blogs.map((elem: Blog, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    background: "#3d3d3d",
                    cursor: "pointer",
                    p: "0.5rem",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(`/blog/${elem.title}`);
                  }}
                >
                  <img
                    src={elem.headImage}
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "cover",
                      borderRadius: "20px 20px 0 0",
                    }}
                  />
                  <Typography>
                    {typeof elem.createdAt == "string" ? elem.createdAt : ""} |{" "}
                    {elem.writer}
                  </Typography>
                  <Typography sx={{fontSize: "1.5rem"}}>
                    {elem.title.split("-").join(" ")}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
}
