import {collection, doc, setDoc} from "firebase/firestore";
import {Doc} from "src/Models/Form/FieldModel";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {db, storage} from "src/firebaseConfig";

export default async function saveDoc(
  docField: Doc,
  uid: string,
  value: string,
  selectedFile: File,
) {
  const tempUser = uid.length == 20;
  const docRef = doc(
    collection(doc(db, "users", uid), "kycDocs"),
    docField.name.toUpperCase(),
  );
  const storageRef = ref(
    storage,
    `${tempUser ? "tempUsers" : "users"}/${uid}/files/${selectedFile.name}`,
  );
  await uploadBytes(storageRef, selectedFile);
  const filePath = await getDownloadURL(storageRef);
  await setDoc(docRef, {
    url: filePath,
    uid,
    value,
    verifed: false,
  });
  return filePath;
}
