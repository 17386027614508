import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {app} from "src/firebaseConfig";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      }
    });
    const handleBodyClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null;
      if (target && !target.closest("#avatar") && !target.closest("#menu")) {
        setOpenMenu(false);
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  function handleClose() {
    const hamburger = document.getElementById("hamburger") as HTMLElement;
    hamburger.classList.remove("toggle");
    setOpen(false);
  }

  function handleOpen() {
    const hamburger = document.getElementById("hamburger") as HTMLElement;
    hamburger.classList.add("toggle");
    setOpen(true);
  }

  function handleBookDemo() {
    const imagine_link = document.createElement("a");
    imagine_link.href = "https://calendly.com/last2braincells/siff-demo-call";
    imagine_link.click();
    imagine_link.remove();
  }

  function handleDownloadApp() {
    const download_link = document.createElement("a");
    download_link.href =
      "https://play.google.com/store/apps/details?id=com.l2bc.siff";
    download_link.click();
    download_link.remove();
  }

  function handleLogout() {
    const auth = getAuth(app);
    auth.signOut().then(() => {
      toast.success("Logged Out");
      console.log("Logged out");
      handleClose();
      setLoggedIn(false);
    });
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "60px",
            md: "90px",
          },
          position: "sticky",
          top: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "black",
          zIndex: "2",
        }}
      >
        <Box
          sx={{
            width: "90vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src="/Logo2.svg"
            width={"130px"}
            style={{cursor: "pointer"}}
            onClick={() => {
              navigate("/");
            }}
          />
          <div
            className="burger"
            onClick={open ? handleClose : handleOpen}
            id="hamburger"
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
          <div className="nav">
            {/* <Typography className="nav-link">Pricing</Typography> */}
            <Typography
              className="nav-link"
              onClick={() => {
                navigate("/blog");
              }}
            >
              Blogs
            </Typography>
            <Typography onClick={handleBookDemo} className="nav-link">
              Book a demo
            </Typography>
            {/* <Typography className="nav-link">Solutions</Typography> */}
            <Typography
              className="nav-link"
              onClick={() => {
                navigate("/aboutus");
              }}
            >
              About us
            </Typography>
            <Button
              // fullWidth
              onClick={() => {
                handleDownloadApp();
              }}
              sx={{
                margin: "0 0",
                width: "150px",
                bgcolor: "#4361EE",
                color: "white",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                py: "10px",
                transition: "box-shadow 0.5s ease", // Add transition for smooth effect
                "&:hover": {
                  boxShadow: "0px 0px 10px 5px rgba(255, 255, 255, 0.3)",
                },
              }}
            >
              <Typography
                textTransform="none"
                fontSize={"1rem"}
                fontWeight={500}
                color={"#d9d9d9"}
              >
                Download
              </Typography>
            </Button>
            {loggedIn ? (
              <Box sx={{position: "relative"}}>
                <IconButton onClick={handleMenuClick} id="avatar">
                  <Avatar />
                </IconButton>
                {openMenu && (
                  <Box
                    sx={{
                      position: "absolute",
                      background: "#2f2f2f",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                      p: "5px 0",
                      gap: "8px",
                    }}
                    id="menu"
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Box>
                )}
              </Box>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          mt: "2rem",
          transition: "all 1s ease",
          zIndex: "10",
          background: "black",
          width: "100vw",
          height: "100vh",
          overflowY: "hidden",
          position: "fixed",
          top: "30px",
        }}
      >
        <Typography
          fontSize={"16px"}
          sx={{cursor: "pointer"}}
          onClick={() => {
            navigate("/blog");
          }}
        >
          Blogs
        </Typography>
        <Typography
          fontSize={"16px"}
          sx={{cursor: "pointer"}}
          onClick={handleBookDemo}
        >
          Book a demo
        </Typography>

        <Typography
          fontSize={"16px"}
          sx={{cursor: "pointer"}}
          onClick={() => {
            navigate("/aboutus");
          }}
        >
          About us
        </Typography>
        {loggedIn ? (
          <Typography fontSize={"16px"} onClick={handleLogout}>
            Logout
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
