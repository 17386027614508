import {Box, FormControl, TextField, Typography} from "@mui/material";
import FieldModel from "src/Models/Form/FieldModel";
import {ISubmission} from "src/Pages/FormFillup/FormFillup";
import {Dispatch, SetStateAction} from "react";

interface ConditionalFieldProps {
  field: FieldModel;
  submission: ISubmission;
  setSubmission: Dispatch<SetStateAction<ISubmission>>;
}

export default function ConditionalField({
  field,
  submission,
  setSubmission,
}: ConditionalFieldProps) {
  if (
    (field.inputType == "Drop down" || field.inputType == "Multiple choice") &&
    field.subFields
  ) {
    const subfieldQuestion = field.subFields?.find(
      (element) =>
        element.option.option == submission[field.id ?? field.question],
    );
    if (subfieldQuestion) {
      return (
        <Box paddingTop="2rem">
          <Typography fontWeight={600} paddingBottom="7px">
            {subfieldQuestion.question}
          </Typography>
          <FormControl fullWidth>
            <TextField
              type="text"
              label=""
              value={
                submission[subfieldQuestion.id ?? subfieldQuestion.question]
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                width: "96%",
                bgcolor: "#262626",
                borderRadius: "25px",
                outline: "none",
                border: "none",
                paddingX: "15px",
                paddingY: "3px",
              }}
              onChange={(e) => {
                setSubmission({
                  ...submission,
                  [subfieldQuestion.id ?? subfieldQuestion.question]:
                    e.target.value,
                });
              }}
            />
          </FormControl>
        </Box>
      );
    }
  }
  return <></>;
}
