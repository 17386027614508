import {Box, Button, Container, Typography} from "@mui/material";
import QRCode from "react-qr-code";

export default function SignIn() {
  return (
    <Box
      width="100vw"
      height="100vh"
      bgcolor="#4361EE"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: "url(/signinback.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          width: {
            md: "60vw",
          },
          height: {
            md: "60vh",
          },
          background: "#262626",
          borderRadius: "30px",
        }}
      >
        <Container
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            minHeight: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: {
                xs: "center",
                md: "start",
              },
              borderRight: {
                xs: "0px solid #3c3c3c",
                md: "1px solid #3C3C3C",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.6rem",
                color: "#FFFFFF",
                fontWeight: "600",
                m: "3% 0",
              }}
            >
              Welcome back!
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#999999",
                mb: "5%",
                textAlign: {
                  xs: "center",
                  md: "start",
                },
              }}
            >
              Create forms, verify people, analyze responses & more
            </Typography>
            <Box sx={{display: "flex", alignItems: "center", mb: "5%"}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  d="M17.0065 17V22M17.0065 12H16.9898M2 17C2 25.2843 8.71567 32 17 32C20.394 32 31.9988 32 31.9988 32C31.9988 32 29.4001 25.7602 30.4401 23.668C31.4386 21.6593 32 19.3953 32 17C32 8.71574 25.2843 2 17 2C8.71567 2 2 8.71574 2 17Z"
                  stroke="white"
                  strokeWidth="3.33334"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                sx={{
                  width: {
                    xs: "100%",
                    md: "100%",
                  },
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#FFFFFF",
                  ml: "4%",
                }}
              >
                How to sign in?
              </Typography>
            </Box>
            <Box sx={{width: "100%"}}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "3%",
                }}
              >
                <Typography
                  sx={{
                    background: "white",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    borderRadius: "50%",
                    fontSize: "1rem",
                    fontWeight: "600",
                    mr: "2%",
                  }}
                >
                  1
                </Typography>
                <Typography>Open SIFF app & register</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "3%",
                }}
              >
                <Typography
                  sx={{
                    background: "white",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    borderRadius: "50%",
                    fontSize: "1rem",
                    fontWeight: "600",
                    mr: "2%",
                  }}
                >
                  2
                </Typography>
                <Typography>Click “Scan any QR code”</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "3%",
                }}
              >
                <Typography
                  sx={{
                    background: "white",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    borderRadius: "50%",
                    fontSize: "1rem",
                    fontWeight: "600",
                    mr: "2%",
                  }}
                >
                  3
                </Typography>
                <Typography>Set duration & name. That’s all!</Typography>
              </Box>
            </Box>
            <Button variant="text" sx={{width: "50%"}}>
              Don’t have the app?
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: "70%",
                display: "flex",
                alignItems: "center",
                background: "white",
                borderRadius: "40px",
                "&:hover": {
                  background: "white",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  mr: "2%",
                  textTransform: "none",
                  fontWeight: "800",
                }}
              >
                Download SIFF App
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 37 38"
                fill="none"
                style={{marginRight: "2%"}}
              >
                <g clipPath="url(#clip0_4_955)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.181 6.40681C25.5298 4.84358 26.439 2.66631 26.1902 0.5C24.2465 0.574 21.895 1.74162 20.5013 3.303C19.2498 4.68863 18.1573 6.90266 18.4511 9.02644C20.6192 9.18737 22.8323 7.97188 24.181 6.40681ZM29.043 20.1563C29.0973 25.7561 34.1688 27.6189 34.2249 27.643C34.1837 27.7743 33.4149 30.2973 31.5535 32.9057C29.9428 35.159 28.2723 37.4031 25.6402 37.4512C23.0549 37.4975 22.2224 35.9824 19.2648 35.9824C16.3091 35.9824 15.385 37.4029 12.9381 37.4973C10.3976 37.5879 8.46144 35.0594 6.83953 32.8136C3.52089 28.2201 0.986073 19.8327 4.39077 14.1718C6.0819 11.3616 9.10309 9.57941 12.3843 9.53501C14.878 9.48876 17.2332 11.1429 18.7578 11.1429C20.2825 11.1429 23.1447 9.15419 26.1528 9.44649C27.4118 9.49644 30.9474 9.93288 33.2166 13.1167C33.0333 13.2258 28.9981 15.4758 29.043 20.1563Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4_955">
                    <rect
                      width="37"
                      height="37"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <svg
                width="20"
                height="20"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="playstore 1">
                  <path
                    id="Vector"
                    d="M9.65828 2.21659L28.025 12.8249L22.7999 18.0499L6.33328 1.74159C6.64995 1.74159 7.12495 1.58325 7.44162 1.58325C8.23328 1.58325 9.02495 1.74159 9.65828 2.21659ZM3.32495 32.2999V5.69992C3.32495 4.43325 3.95828 3.32492 4.90828 2.53325L21.5333 19.1583L5.06662 35.6249C3.95828 34.8333 3.32495 33.5666 3.32495 32.2999ZM9.65828 35.9416C8.70828 36.4166 7.59995 36.5749 6.64995 36.4166L22.7999 20.4249L27.8666 25.4916L9.65828 35.9416ZM32.6166 22.6416L29.2916 24.5416L23.75 19.1583L29.2916 13.6166L32.4583 15.5166C33.725 16.3083 34.5166 17.5749 34.5166 19.1583C34.6749 20.4249 33.8833 21.8499 32.6166 22.6416Z"
                    fill="black"
                  />
                </g>
              </svg>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QRCode
              value={JSON.stringify({type: "signin", id: "id"})}
              fgColor="white"
              bgColor="black"
              style={{width: "50%"}}
            />
            <Typography
              variant="h3"
              align="center"
              color={"#FFFFFF"}
              sx={{mt: "5%"}}
            >
              Scan this QR to sign in
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color={"#999999"}
              width={"80%"}
              sx={{m: "2% 0"}}
            >
              Scan this with the SIFF mobile app to sign in instantly.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
