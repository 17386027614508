import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {Avatar, Box, Button, Container, Typography} from "@mui/material";
import {doc, getDoc} from "firebase/firestore";
import {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {db} from "src/firebaseConfig";
import {ISubmission} from "../FormFillup/FormFillup";
import toast from "react-hot-toast";

export default function Submission() {
  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);

  const submissionData = state?.submissionData;
  const afterSubmissionData = state?.obj;
  const userCred = useMemo(() => {
    if (state.tempUser) {
      return state.tempUser;
    }
    return state.obj;
  }, state);
  const [userData, setUserData] = useState<ISubmission>({});
  const [showGif, setShowGif] = useState(true);

  function stringToColor(): string {
    const colors = [
      "#7289d9",
      "#dc143b",
      "#ea967a",
      "#f0807f",
      "#bdb76b",
      "#ee82ef",
      "#ba56d4",
      "#4b0081",
      "#6b5acd",
      "#800000",
      "#008081",
      "#191971",
      "#d3691f",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  function truncateString(str: string) {
    const num = 16;
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  useEffect(() => {
    if (submissionData) {
      if (Object.keys(userData).length == 0) {
        if (userCred.name) {
          const names = userCred.name.split(" ");
          setUserData({firstname: names[0], lastname: names[1]});
        } else {
          const userRef = doc(db, "users", userCred.user);
          getDoc(userRef).then((user) => {
            if (user.exists()) {
              const userDetails = user.data();
              setUserData(userDetails);
            }
          });
        }
      }
    } else {
      navigate("/");
    }

    const gifTimeout = setTimeout(() => {
      setShowGif(false);
    }, 3000);

    return () => clearTimeout(gifTimeout);
  }, [userData]);

  if (Object.keys(userData).length == 0) {
    return null;
  }

  return (
    <Box
      width={["90%", "80%", "50%", "35%"]}
      display="flex"
      flexDirection="column"
      paddingTop="20px"
      paddingBottom="4rem"
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{cursor: "pointer"}}
          onClick={() => {
            navigate("/");
          }}
        >
          <ArrowBackIosIcon />
        </div>

        <img
          src={"/Logo2.svg"}
          style={{cursor: "pointer"}}
          onClick={() => {
            navigate("/");
          }}
        />
        <Typography
          style={{cursor: "pointer"}}
          onClick={() => {
            navigate("/help");
          }}
        >
          Help
        </Typography>
      </Box>
      {!showGif && (
        <img
          src="/afterSubmissionHead.svg"
          style={{width: "80%", margin: " 0 auto", marginTop: "1rem"}}
        />
      )}
      <Container
        sx={{
          background: "#262626",
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem 1rem",
          borderRadius: "30px",
          marginTop: showGif ? "180px" : "1rem", // GIF required more marginTOP
        }}
      >
        {showGif ? (
          <img
            src="/submitted.gif"
            style={{width: "100%", height: "100%", objectFit: "cover"}}
          />
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={
                  submissionData.image
                    ? submissionData.image
                    : "/submissionImage.svg"
                }
                style={{
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
            </div>
            <Typography
              sx={{
                fontWeight: "600",
                textAlign: "center",
                fontSize: "16px",
                maxWidth: "100%",
                wordBreak: "break-all",
                marginTop: "10px",
              }}
            >
              {submissionData.title}
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#ABACAC",
                textAlign: "center",
                maxWidth: "100%",
                wordBreak: "break-all",
                marginTop: "5px",
              }}
            >
              {submissionData.description}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#ABACAC",
                marginTop: "2.5rem",
                fontWeight: "600",
              }}
            >
              {afterSubmissionData.time}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#617AEC",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {`Ref. no: ${afterSubmissionData.refId}`}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "3rem",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "75%",
                  margin: "0.5rem auto",
                }}
              >
                <Typography sx={{fontSize: "0.5rem"}}>Submitted By</Typography>
                <Typography sx={{fontSize: "0.5rem"}}>Submitted To</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    background: "#323232",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "15px",
                    width: "120px",
                    padding: "1rem 0.5rem",
                  }}
                >
                  {userData.profilePic ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={userData.profilePic}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        background: stringToColor(),
                        color: "inherit",
                      }}
                    >{`${userData.firstname[0].toUpperCase()}${userData.lastname[0].toUpperCase()}`}</Avatar>
                  )}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      lineHeight: "1.2",
                      marginTop: "5px",
                    }}
                  >
                    {truncateString(
                      `${userData.firstname} ${userData.lastname}`,
                    )}
                  </Typography>
                  <Typography
                    sx={{fontSize: "8px", color: "#ABACAC", marginTop: "5px"}}
                  >
                    {userData.username
                      ? userData.username
                      : `${userCred.phone}@siff`}
                  </Typography>
                </Box>
                <ArrowRightAltIcon sx={{color: "#ABACAC"}} fontSize="large" />
                <Box
                  sx={{
                    background: "#323232",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "15px",
                    width: "120px",
                    maxWidth: "120px",
                    padding: "1rem 0.5rem",
                  }}
                >
                  {submissionData.ownerProfile ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={submissionData.ownerProfile}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        background: stringToColor(),
                        color: "inherit",
                      }}
                    >{`${submissionData.ownerName
                      .split(" ")
                      .map((namePart: string) => namePart[0].toUpperCase())
                      .join("")}`}</Avatar>
                  )}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      lineHeight: "1.2",
                      marginTop: "5px",
                    }}
                  >
                    {truncateString(submissionData.ownerName)}
                  </Typography>
                  <Typography
                    sx={{fontSize: "8px", color: "#ABACAC", marginTop: "5px"}}
                  >
                    {submissionData.ownerUsername}
                  </Typography>
                </Box>
              </div>
            </div>
            {submissionData.confMessage && (
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.6rem",
                  maxWidth: "600px",
                }}
              >
                <Typography
                  sx={{color: "#ABACAC", fontSize: "10px", fontStyle: "italic"}}
                >{`Message by ${submissionData.ownerName}`}</Typography>
                <Box
                  sx={{
                    bgcolor: "rgba(239, 206, 32,0.25)",
                    padding: "1rem",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{fontSize: "12px", textAlign: "center"}}>
                    {submissionData.confMessage}
                  </Typography>
                </Box>
              </div>
            )}
          </div>
        )}
      </Container>
      {showGif ? (
        <></>
      ) : (
        <>
          <Typography color={"white"} textAlign={"center"} fontSize={"16px"}>
            Your Submission
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr 1fr",
              gap: "10px",
              margin: "10px auto",
              maxWidth: "800px",
            }}
          >
            <Button
              onClick={() => {
                toast.error("This feature is not available on this form", {
                  id: "nofeature",
                });
              }}
              sx={{
                bgcolor: "#000000",
                color: "white",
                borderRadius: "25px",
                border: "1px solid white",
                padding: "0.5rem 1.5rem",
                "&:hover": {
                  color: "#000000",
                  bgcolor: "white",
                },
              }}
            >
              <Typography fontSize={"14px"} textTransform="none">
                View
              </Typography>
            </Button>
            <Button
              onClick={() => {
                toast.error("This feature is not available on this form", {
                  id: "nofeature",
                });
              }}
              sx={{
                bgcolor: "#000000",
                color: "white",
                borderRadius: "25px",
                border: "1px solid white",
                padding: "0.5rem 1rem",
                "&:hover": {
                  color: "#000000",
                  bgcolor: "white",
                },
                overflow: "hidden",
              }}
            >
              <Typography
                fontSize={"14px"}
                whiteSpace={"nowrap"}
                textTransform="none"
              >
                Submit another
              </Typography>
            </Button>
            <Button
              onClick={() => {
                toast.error("This feature is not available on this form", {
                  id: "nofeature",
                });
              }}
              sx={{
                bgcolor: "#000000",
                color: "white",
                borderRadius: "25px",
                border: "1px solid white",
                padding: "0.5rem 1.5rem",
                "&:hover": {
                  color: "#000000",
                  bgcolor: "white",
                },
              }}
            >
              <Typography fontSize={"14px"} textTransform="none">
                Edit
              </Typography>
            </Button>
          </div>
        </>
      )}
    </Box>
  );
}
