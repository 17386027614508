import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {db} from "src/firebaseConfig";
import {doc, getDoc} from "firebase/firestore";

export default function DynamicQr() {
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const qrRef = doc(db, "qrcodes", id);
      getDoc(qrRef).then((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const feature = data.feature;
          const featureId = data.id;
          if (feature && featureId) {
            if (feature == "forms") {
              navigate(`/form/${featureId}`);
            }
          }
        }
      });
    }
  }, []);
  return <></>;
}
