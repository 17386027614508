import {Box, List, Typography} from "@mui/material";

export default function Policies() {
  return (
    <Box sx={{padding: "20px"}}>
      <Typography variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        1. Introduction
      </Typography>
      <p>
        This Privacy Policy explains how we, <b>Last 2 Brain Cells</b> collect,
        use, disclose, and protect your personal information when you use our
        app.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        2. Information We Collect
      </Typography>
      <List sx={{listStyle: "lower-alpha", paddingLeft: "30px"}}>
        <li>
          User-Provided Information: We may collect information that you provide
          when using the App, such as your name, email address, and other
          personal details.
        </li>
        <li>
          Automatically Collected Information: We may collect certain
          information automatically, including but not limited to your device
          type, IP address, and usage data.
        </li>
        <li>
          In addition to the information mentioned previously, we may also
          collect data from your government-issued documents with your explicit
          consent. This data may include, but is not limited to:
          <ul style={{paddingLeft: "20px"}}>
            <li>Name</li>
            <li>Address</li>
            <li>Date of birth</li>
            <li>Identification number (e.g., Aadhaar number, PAN number)</li>
          </ul>
        </li>
      </List>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        3. How We Use Your Information
      </Typography>
      <List sx={{listStyle: "lower-alpha", paddingLeft: "30px"}}>
        <li>
          <b>Providing Services:</b> To provide and maintain the features and
          functionality of the App.
        </li>
        <li>
          <b>Pre-filling digital forms</b>: With your permission, we can use the
          data from your government-issued documents to automatically fill out
          digital forms created by other users. This can save you time and
          effort when filling out repetitive information.
        </li>
        <li>
          <b>Enhancing Auto-filling:</b> We may analyze the data you submit
          through the app, including form entries and selections, to improve the
          auto-filling feature and suggest relevant information for future
          submissions. This analysis is performed anonymously and does not
          identify individual users.
        </li>
        <li>
          <b>Communication:</b> To communicate with you regarding updates,
          announcements, and other relevant information.
        </li>
        <li>
          <b>Analytics:</b> To analyze usage patterns and improve the user
          experience.
        </li>
        <li>
          <b>Payment Processing:</b> If applicable, to process payments and
          prevent fraudulent activities.
        </li>
      </List>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        4. Information Sharing
      </Typography>
      <p>
        We never share your data from government-issued documents with any
        third-party without your explicit consent. The data is used solely for
        pre-filling forms within the app, and we implement robust security
        measures to protect its confidentiality and integrity.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        5. Security
      </Typography>
      <p>
        We implement security measures to maintain the safety of your personal
        information. However, no method of transmission over the internet or
        electronic storage is 100% secure, and we cannot guarantee absolute
        security.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        6. Cookies and Tracking Technologies
      </Typography>
      <p>
        We may use cookies and similar tracking technologies to enhance your
        experience on the App. You can adjust your browser settings to disable
        cookies, but this may affect certain features of the App.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        7. Third-Party Links
      </Typography>
      <p>
        The App may contain links to third-party websites. We have no control
        over the content or privacy policies of these sites and encourage you to
        review their privacy policies.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        8. Children&apos;s Privacy
      </Typography>
      <p>
        The App is not directed to individuals under the age of 13. We do not
        knowingly collect personal information from children under 13. If you
        are a parent or guardian and believe your child has provided us with
        personal information, please contact us.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        9. Data Retention and Deletion
      </Typography>
      <p>
        We retain your personal information for as long as necessary to fulfill
        the purposes described in this Privacy Policy, unless a longer retention
        period is required or permitted by law. We will then delete your
        personal information in a secure manner.
      </p>
      <p>
        You can also request to delete your personal information at any time by
        contacting us through the methods provided in this policy. We will
        respond to your request within a reasonable timeframe.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        10. Changes to This Privacy Policy
      </Typography>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <Typography variant="h5" sx={{paddingTop: "10px"}}>
        11. Contact Information
      </Typography>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a href="mailto:contact@last2braincells.com">
          contact@last2braincells.com
        </a>
      </p>
    </Box>
  );
}
